import React from 'react'
import { useDispatch, connect } from 'react-redux'

import { deactivateModal } from '../../../../store/slices/modalSlice'
import InputField from '../../../../Components/UiComponents/InputField/InputField'

import './styles.editInfoModal.css'

const EditInfoModal = ({ user }) => {

    const dispatch = useDispatch()

    return (
        <div className='edit-info-modal-wrapper'>
            <div className='edit-info-modal-form-wrapper'>
                <div className='edit-info-modal-header'>
                    <p className='edit-info-modal-title-txt'>Update your info</p>
                    <p className='edit-info-modal-exit-txt' onClick={() => {
                        dispatch(deactivateModal());
                    }}>X</p>
                </div>
                <div className='edit-info-modal-body'>
                    <div className='edit-info-modal-inputs-wrapper'>
                        <div className='edit-info-modal-input-container'>
                            <p className='edit-info-modal-normal-txt'>First Name: </p>
                            <InputField placeholder={user.first_name} type='text' />
                        </div>
                        <div className='edit-info-modal-input-container'>
                            <p className='edit-info-modal-normal-txt'>Last Name: </p>
                            <InputField placeholder={user.last_name} type='text' />
                        </div>
                        <div className='edit-info-modal-input-container'>
                            <p className='edit-info-modal-normal-txt'>Phone number: </p>
                            <InputField placeholder={user.phone_number} type='text' />
                        </div>
                        <div className='edit-info-modal-input-container'>
                            <p className='edit-info-modal-normal-txt'>Email: </p>
                            <InputField placeholder={user.email} type='text' />
                        </div>
                    </div>
                    {/* <div className='edit-info-modal-pic-wrapper'>
                        <p>pic</p>
                    </div> */}
                </div>
                <button className='edit-info-modal-button'>Update Info</button>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user.user,
})

export default connect(mapStateToProps)(EditInfoModal)
import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

import { REFRESH_TOKEN } from "../constants/url";
import { SERVER_ADDRESS } from "../constants/app.constants";

let accessToken = localStorage.getItem("accessToken");
let refreshToken = localStorage.getItem("refreshToken");

const axiosInstance = axios.create({
	SERVER_ADDRESS,
	headers: { Authorization: `Bearer ${accessToken}` },
});

axiosInstance.interceptors.request.use(async (req) => {
	accessToken = localStorage.getItem("accessToken");

	if (accessToken) {
		const user = jwt_decode(accessToken);
		const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

		if (isExpired) {
			refreshToken = localStorage.getItem("refreshToken");
			const response = await axios.post(REFRESH_TOKEN, {
				refresh: refreshToken,
			});

			localStorage.setItem("accessToken", response.data.access);
			accessToken = response.data.access;
		}
	}

	req.headers.Authorization = `Bearer ${accessToken}`;

	return req;
});

export default axiosInstance;

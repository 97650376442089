import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import ModalController from "./ModalController";
import { fetchUser } from "../../store/slices/userSlice";
import { DATA_STATE } from "../../constants/app.constants";
import { setActiveRoute } from "../../store/slices/routingSlice";
import NotificationContainer from "../Notifications/NotificationContainer";
import LoadingModalOverlay from "../UiComponents/LoadingModalOverlay/LoadingModalOverlay";

import "./styles.css";

const AppBase = ({ children, dataState, activeRoute }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(fetchUser());
	}, [dispatch]);

	useEffect(() => {
		if (activeRoute) {
			navigate(activeRoute);
			dispatch(setActiveRoute(null));
		}
	}, [activeRoute, navigate, dispatch]);

	return (
		<div className="app-body">
			{dataState === DATA_STATE.DATA_STATE_LOADING ? (
				<LoadingModalOverlay />
			) : null}
			<ModalController />
			<NotificationContainer />
			{children}
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	token: state.user.accessToken,
	dataState: state.appState.dataState,
	activeRoute: state.activeRoute.activeRoute,
});

export default connect(mapStateToProps)(AppBase);

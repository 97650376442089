import { createSlice } from "@reduxjs/toolkit";

import {
	getUserData,
	getCompanyCameras,
	getCompanyAlertPhones,
} from "../../Components/AppBase/appBase.services";

import { setActiveRoute } from "./routingSlice";
import { displayNotification, setDataState } from "./appStateSlice";
import { ACTIVE_ROUTES, DATA_STATE } from "../../constants/app.constants";
import { filterAlertsByStatusNoAction } from "../../Pages/HistoryPage/historyPage.actions";

const initialState = {
	user: null,
	filterStatus: "",
	accessToken: null,
	refreshToken: null,
	activeCamera: null,
	activeCompany: null,
	alertsForCamera: null,
	activeCompanyAlerts: null,
	activeCompanyCameras: null,
	activeCompanyAlertPhones: null,
	filteredActiveCompanyAlerts: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setToken: (state, action) => {
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
		},
		setActiveCompany: (state, action) => {
			state.activeCompany = action.payload;
		},
		setActiveCompanyCameras: (state, action) => {
			state.activeCompanyCameras = action.payload;
		},
		setActiveCamera: (state, action) => {
			state.activeCamera = action.payload;
		},
		setActiveCompanyAlertPhones: (state, action) => {
			state.activeCompanyAlertPhones = action.payload;
		},
		setActiveCompanyAlerts: (state, action) => {
			state.activeCompanyAlerts = action.payload;
			if (state.filterStatus !== "") {
				const newAlerts = filterAlertsByStatusNoAction(
					state.filterStatus,
					action.payload,
				);
				state.filteredActiveCompanyAlerts = newAlerts;
			}
		},
		setFilteredActiveCompanyAlerts: (state, action) => {
			state.filteredActiveCompanyAlerts = action.payload.newAlerts;
			state.filterStatus = action.payload.status;
		},
		resetFilter: (state) => {
			state.filteredActiveCompanyAlerts = null;
			state.filterStatus = "";
		},
		setAlertsForCamera: (state, action) => {
			state.alertsForCamera = action.payload;
		},
	},
});

export const {
	setUser,
	setToken,
	resetFilter,
	setActiveCamera,
	setActiveCompany,
	setAlertsForCamera,
	setActiveCompanyAlerts,
	setActiveCompanyCameras,
	setActiveCompanyAlertPhones,
	setFilteredActiveCompanyAlerts,
} = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectAccessToken = (state) => state.user.accessToken;
export const selectFilterStatus = (state) => state.user.filterStatus;
export const selectRefreshToken = (state) => state.user.refreshToken;
export const selectActiveCompany = (state) => state.user.activeCompany;
export const selectActiveCompanyAlertPhones = (state) =>
	state.user.activeCompanyAlertPhones;
export const selectActiveCompanyAlerts = (state) =>
	state.user.activeCompanyAlerts;
export const selectFilteredActiveCompanyAlerts = (state) =>
	state.user.filteredActiveCompanyAlerts;
export const selectAlertsForCamera = (state) => state.user.alertsForCamera;

export const fetchUser = (companyId) => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	let userData = await getUserData(localStorage.getItem("accessToken"));
	if (userData.data) {
		dispatch(setUser(userData.data));
		if (companyId) {
			const company = userData.data.companies.find(
				(company) => company.id === companyId,
			);
			dispatch(setActiveCompany(company));
		} else {
			dispatch(setActiveCompany(userData.data.companies[0]));
		}
		let activeCompanyCameras = null;
		if (companyId) {
			activeCompanyCameras = await getCompanyCameras(companyId);
		} else {
			activeCompanyCameras = await getCompanyCameras(
				userData.data.companies[0].id,
			);
		}
		if (activeCompanyCameras.data)
			dispatch(setActiveCompanyCameras(activeCompanyCameras.data.results));
		let activeCompanyAlertPhones = await getCompanyAlertPhones(
			userData.data.companies[0].id,
		);
		if (activeCompanyAlertPhones.data)
			dispatch(setActiveCompanyAlertPhones(activeCompanyAlertPhones.data));
	} else if (userData.error) {
		dispatch(setActiveRoute(ACTIVE_ROUTES.LOGIN));
		dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
		localStorage.clear();
	}
	dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
};

export const fetchCompanyAlertPhones = (companyId) => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	let activeCompanyAlertPhones = await getCompanyAlertPhones(companyId);
	if (activeCompanyAlertPhones.data) {
		dispatch(setActiveCompanyAlertPhones(activeCompanyAlertPhones.data));
		dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
	}
};

export const fetchCompanyCameras = (companyId) => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	let activeCompanyCameras = await getCompanyCameras(companyId);
	if (activeCompanyCameras.data) {
		dispatch(setActiveCompanyCameras(activeCompanyCameras.data.results));
	} else if (activeCompanyCameras.error) {
		dispatch(displayNotification("There was a problem fetching cameras"));
	}
	dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
};

export default userSlice.reducer;

import axiosInstance from "../../utils/axios";
import {
  CREATE_COMPANY_ALERT_PHONE,
  DELETE_COMPANY_ALERT_PHONE,
  GET_COMPANY_ALERT_PHONES,
  GET_COMPANY_CAMERAS,
  GET_USER_DATA,
} from "../../constants/url";

export const getUserData = async () => {
  let data;
  let error;

  await axiosInstance
    .get(GET_USER_DATA)
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      error = err.message;
    });

  return { data, error };
};

export const getCompanyCameras = async (companyId) => {
  let data;
  let error;

  await axiosInstance
    .get(GET_COMPANY_CAMERAS(companyId))
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      error = err.message;
    });

  return { data, error };
};

export const getCompanyAlertPhones = async (companyId) => {
  let data;
  let error;

  await axiosInstance
    .get(GET_COMPANY_ALERT_PHONES(companyId))
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      console.log(err);
      error = err.message;
    });

  return { data, error };
};

export const deleteCompanyAlertPhone = async (phoneId) => {
  let data;
  let error;

  await axiosInstance
    .delete(DELETE_COMPANY_ALERT_PHONE(phoneId))
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      error = err.message;
    });

  return { data, error };
};

export const createCompanyAlertPhone = async (dataToSend) => {
  let data;
  let error;

  await axiosInstance
    .post(CREATE_COMPANY_ALERT_PHONE, dataToSend)
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      error = err.message;
    });

  return { data, error };
};

import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';

import './styles.css'

const LoadingModalOverlay = () => {
    return (
        <div className='loading-modal-component-wrapper'>
            <p className='loading-modal-txt'>Loading...</p>
            <CircularProgress color='primary' size={60} thickness={5} />
        </div>
    )
}

export default LoadingModalOverlay
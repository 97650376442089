import { createSlice } from "@reduxjs/toolkit";
import { DATA_STATE } from "../../constants/app.constants";

const initialState = {
	dataState: DATA_STATE.DATA_STATE_OK,
	dataStateSilent: {
		action: null,
		dataState: DATA_STATE.DATA_STATE_OK,
	},
	notification: {
		displayNotification: false,
		notificationText: "",
		notificationType: null,
	},
};

export const appStateSlice = createSlice({
	name: "appState",
	initialState,
	reducers: {
		setDataState: (state, action) => {
			state.dataState = action.payload;
		},
		displayNotification: (state, action) => {
			state.notification.displayNotification = true;
			state.notification.notificationText = action.payload.text;
			state.notification.notificationType = action.payload.type
				? action.payload.type
				: null;
		},
		removeNotification: (state) => {
			state.notification.displayNotification = false;
		},
		setNotificationText: (state, action) => {
			state.notification.notificationText = action.payload;
		},
		setDataStateSilent: (state, action) => {
			const payload = action.payload;
			state.dataStateSilent.action = payload.action;
			state.dataStateSilent.dataState = payload.dataState;
		},
	},
});

export const {
	setDataState,
	setDataStateSilent,
	removeNotification,
	displayNotification,
	setNotificationText,
} = appStateSlice.actions;

export const selectDataState = (state) => state.appState.dataState;
export const selectDataStateSilent = (state) => state.appState.dataStateSilent;

export default appStateSlice.reducer;

import React from "react";

import "./styles.css";

const InputField = ({ type, handleInput, placeholder, onBlur, onChange }) => {
	return (
		<input
			type={type}
			className="input-field"
			onChange={onChange}
			placeholder={placeholder}
			onBlur={onBlur}
		/>
	);
};

export default InputField;

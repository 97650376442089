import * as Yup from "yup";
import { useMemo } from "react";
import { Button, Tooltip } from "@mui/material";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { addNewCameraAction } from "./addNewCameraModal.actions";
import { selectActiveCompany } from "../../../../../store/slices/userSlice";
import MapFormik from "../../../../../Components/UiComponents/FormikComponents/MapFormik";
import ModalContainer from "../../../../../Components/UiComponents/ModalContainer/ModalContainer";
import InputAreaFormik from "../../../../../Components/UiComponents/FormikComponents/InputAreaFormik";
import InputFieldFormik from "../../../../../Components/UiComponents/FormikComponents/InputFieldFormik";

import "./addNewCameraModal.styles.css";
import "../EditCameraModal/editCameraModal.styles.css";

const buttonSx = {
	backgroundColor: "#213a44",
	width: "100%",
};

const FORM_FIELDS = {
	NAME: "name",
	IP_ADDRESS: "ip_address",
	USERNAME: "username",
	PASSWORD: "password",
	DESCRIPTION: "description",
	COORDS: "coords",
	LAT: "lat",
	LON: "lon",
	COMPANY_ID: "company",
};

function ipv4(message = "Invalid IP address") {
	return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
		message,
		excludeEmptyString: true,
	}).test("ip", message, (value) => {
		return value === undefined || value.trim() === ""
			? true
			: value.split(".").find((i) => parseInt(i, 10) > 255) === undefined;
	});
}

const AddNewCameraModal = () => {
	const dispatch = useDispatch();
	const company = useSelector(selectActiveCompany);

	Yup.addMethod(Yup.string, "ipv4", ipv4);

	const validationSchema = Yup.object().shape({
		[FORM_FIELDS.NAME]: Yup.string()
			.min(3, "Name too short!")
			.max(20, "Name too long!")
			.required("Name is required!"),
		[FORM_FIELDS.IP_ADDRESS]: Yup.string()
			.ipv4()
			.required("IP Address is required!"),
		[FORM_FIELDS.DESCRIPTION]: Yup.string()
			.min(10, "Description too short!")
			.max(100, "Description too long!")
			.required("Description is required!"),
		[FORM_FIELDS.USERNAME]: Yup.string().required("Username is required!"),
		[FORM_FIELDS.PASSWORD]: Yup.string().required("Password is required!"),
		[FORM_FIELDS.COORDS]: Yup.object().required(
			"Please mark the camera location on the map!",
		),
	});

	const initialValues = useMemo(() => {
		return {
			[FORM_FIELDS.NAME]: null,
			[FORM_FIELDS.IP_ADDRESS]: null,
			[FORM_FIELDS.USERNAME]: null,
			[FORM_FIELDS.PASSWORD]: null,
			[FORM_FIELDS.DESCRIPTION]: null,
			[FORM_FIELDS.COORDS]: null,
			[FORM_FIELDS.LAT]: null,
			[FORM_FIELDS.LON]: null,
			[FORM_FIELDS.COMPANY_ID]: null,
		};
	}, []);

	const onSubmit = (formValues) => {
		const newCamera = {
			[FORM_FIELDS.LAT]: formValues.coords.lat,
			[FORM_FIELDS.LON]: formValues.coords.lng,
			[FORM_FIELDS.NAME]: formValues[FORM_FIELDS.NAME],
			[FORM_FIELDS.IP_ADDRESS]: formValues[FORM_FIELDS.IP_ADDRESS],
			[FORM_FIELDS.USERNAME]: formValues[FORM_FIELDS.USERNAME],
			[FORM_FIELDS.PASSWORD]: formValues[FORM_FIELDS.PASSWORD],
			[FORM_FIELDS.DESCRIPTION]: formValues[FORM_FIELDS.DESCRIPTION],
			[FORM_FIELDS.COMPANY_ID]: company.id,
		};
		dispatch(addNewCameraAction(newCamera));
	};

	return (
		<ModalContainer title="Add new camera" width="60%">
			<Formik
				validateOnMount
				initialValues={initialValues}
				validationSchema={validationSchema}
			>
				{({ values, isValid }) => (
					<Form>
						<div className="edit-camera-modal-container">
							<div className="edit-camera-modal-form-container">
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">Name: </p>
									<Field name={FORM_FIELDS.NAME}>
										{({ form, ...formik }) => (
											<InputFieldFormik form={form} {...formik} />
										)}
									</Field>
								</div>
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">IP Address:</p>
									<Field name={FORM_FIELDS.IP_ADDRESS}>
										{({ form, ...formik }) => (
											<InputFieldFormik form={form} {...formik} />
										)}
									</Field>
								</div>
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">Username: </p>
									<Field name={FORM_FIELDS.USERNAME}>
										{({ form, ...formik }) => (
											<InputFieldFormik form={form} {...formik} />
										)}
									</Field>
								</div>
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">Password: </p>
									<Field name={FORM_FIELDS.PASSWORD}>
										{({ form, ...formik }) => (
											<InputFieldFormik
												type="password"
												form={form}
												{...formik}
											/>
										)}
									</Field>
								</div>
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">
										Description:
									</p>
									<Field name={FORM_FIELDS.DESCRIPTION}>
										{({ form, ...formik }) => (
											<InputAreaFormik form={form} {...formik} />
										)}
									</Field>
								</div>
							</div>
							<div className="edit-camera-modal-map-container">
								<Field name={FORM_FIELDS.COORDS}>
									{({ form, ...formik }) => (
										<MapFormik form={form} {...formik} />
									)}
								</Field>
							</div>
						</div>
						<div className="edit-camera-modal-footer">
							{isValid ? (
								<span
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										width: "10%",
									}}
								>
									<Button
										variant="contained"
										sx={buttonSx}
										onClick={() => onSubmit(values)}
										disabled={!isValid}
									>
										<p className="history-page-filters-button-txt">Save</p>
									</Button>
								</span>
							) : (
								<Tooltip title="Please check all fields">
									<span
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
											width: "10%",
										}}
									>
										<Button
											variant="contained"
											sx={buttonSx}
											onClick={() => onSubmit(values)}
											disabled={!isValid}
										>
											<p className="history-page-filters-button-txt">Save</p>
										</Button>
									</span>
								</Tooltip>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</ModalContainer>
	);
};

export default AddNewCameraModal;

import axios from "axios";
import { useEffect, useState } from "react";

const useFetch = (url) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (url)
			axios
				.get(url)
				.then((response) => {
					setData(response.data);
				})
				.catch((error) => {
					setError(error);
				})
				.finally(() => {
					setLoading(false);
				});
	}, [url]);

	return { loading, data, error };
};

export default useFetch;

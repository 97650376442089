import { CircularProgress } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const Map = ({ camera, onChangeHandler, error, setFieldError, fieldName }) => {
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyDEdv7QL2ltC4LjnOyFFy55u_iug6-s41s",
	});

	const containerStyle = {
		width: "100%",
		height: "100%",
	};

	const mapCenter = useMemo(() => {
		if (camera) {
			return { lat: camera.lat, lng: camera.lon };
		} else
			return {
				lat: 0,
				lng: 0,
			};
	}, [camera]);

	const [position, setPosition] = useState(null);
	const [mapLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (camera) {
			setPosition({
				lat: camera.lat,
				lng: camera.lon,
			});
		}
	}, [camera]);

	if (!isLoaded) return <CircularProgress />;

	return (
		<GoogleMap
			center={position ? position : mapCenter}
			onBoundsChanged={() => setIsLoaded(true)}
			zoom={mapCenter.lat !== 0 && mapCenter.lng !== 0 ? 13 : 1.25}
			clickableIcons={false}
			onDrag={() => setFieldError(fieldName, null)}
			onZoomChanged={() => setFieldError(fieldName, null)}
			mapContainerStyle={containerStyle}
			options={{ streetViewControl: false, mapTypeControl: false }}
			onClick={(e) => {
				const position = {
					lat: e.latLng.lat(),
					lng: e.latLng.lng(),
				};
				setPosition(position);
				if (onChangeHandler) {
					onChangeHandler(position);
				}
			}}
		>
			{position !== null ? <Marker position={position ?? mapCenter} /> : null}
			<div
				style={{
					position: "absolute",
					top: 10,
					left: 10,
					display: "flex",
					flexDirection: "column",
					gap: "10px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "baseline",
						justifyContent: "space-between",
						backgroundColor: "rgba(255, 255, 255, 0.7)",
						gap: "10px",
					}}
				>
					<p
						style={{
							display: "flex",
							fontFamily: "Mollen",
							fontSize: "large",
							color: "#213a44",
						}}
					>
						Latitude:
					</p>
					<p
						style={{
							fontFamily: "Mollen",
							fontSize: "x-large",
							color: "#213a44",
							float: "right",
						}}
					>
						{position ? position.lat.toFixed(2) : mapCenter.lat.toFixed(2)}
					</p>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "baseline",
						justifyContent: "space-between",
						backgroundColor: "rgba(255, 255, 255, 0.7)",
						gap: "10px",
					}}
				>
					<p
						style={{
							fontFamily: "Mollen",
							fontSize: "large",
							color: "#213a44",
						}}
					>
						Longitude:
					</p>
					<p
						style={{
							fontFamily: "Mollen",
							fontSize: "x-large",
							color: "#213a44",
						}}
					>
						{position ? position.lng.toFixed(2) : mapCenter.lng.toFixed(2)}
					</p>
				</div>
			</div>
			{error && mapLoaded ? (
				<div
					style={{
						position: "absolute",
						left: 0,
						top: "50%",
						width: "100%",
						background: "rgba(255, 0, 0, 0.7)",
						paddingTop: "5px",
						paddingBottom: "5px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<p
						style={{
							display: "flex",
							fontFamily: "Mollen",
							fontSize: "large",
							color: "white",
						}}
					>
						{error}
					</p>
				</div>
			) : null}
		</GoogleMap>
	);
};

export default Map;

export const weatherIconToCustomIcon = (icon) => {
	switch (icon) {
		case "01n":
			return "clearsky";
		case "02n":
			return "fewclouds";
		case "03n":
			return "fewclouds";
		case "04n":
			return "brokenclouds";
		case "09n":
			return "showerrain";
		case "10n":
			return "rain";
		case "11n":
			return "thunderstorm";
		case "13n":
			return "snow";
		case "50n":
			return "mist";
		default:
			return null;
	}
};

const weatherCategoryToIcon = (category) => {
	switch (category) {
		case "Thunderstorm":
			return "thunderstorm";
		case "Drizzle":
			return "rain";
		case "Rain":
			return "rain";
		case "Snow":
			return "snow";
		case "Atmosphere":
			return "mist";
		case "Clear":
			return "clearsky";
		case "Clouds":
			return "fewclouds";
		default:
			return "fewclouds";
	}
};

export const checkIfIconActive = (icon, category, name) => {
	if (weatherIconToCustomIcon(icon) === name) {
		return true;
	} else if (weatherIconToCustomIcon(icon) === null) {
		const iconFromCategory = weatherCategoryToIcon(category);
		if (iconFromCategory === name) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

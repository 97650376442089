import React from 'react'
import { Outlet } from 'react-router-dom';

import './styles.css'

const MainPageContent = () => {
    return (
        <div className='main-content-component-body'>
            <Outlet />
        </div>
    )
}

export default MainPageContent
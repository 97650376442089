import {
	getCompanyAlertPhones,
	getCompanyCameras,
	getUserData,
} from "../../Components/AppBase/appBase.services";
import { ACTIVE_ROUTES, DATA_STATE } from "../../constants/app.constants";
import {
	GET_COMPANY_ALERT_PHONES,
	GET_COMPANY_CAMERAS,
	GET_USER_DATA,
} from "../../constants/url";
import { setDataState } from "../../store/slices/appStateSlice";
import { setActiveRoute } from "../../store/slices/routingSlice";
import {
	setActiveCompany,
	setActiveCompanyAlertPhones,
	setActiveCompanyCameras,
	setUser,
} from "../../store/slices/userSlice";
import axiosInstance from "../../utils/axios";

export const dispatchFetchUser = () => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	return new Promise((resolve, reject) => {
		axiosInstance
			.get(GET_USER_DATA)
			.then((response) => {
				const userData = response.data;
				dispatch(setUser(userData));
				dispatch(setActiveCompany(userData.companies[0]));
				resolve(userData.companies[0].id);
			})
			.catch((err) => {
				reject(err);
			})
			.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
	});
};

export const dispatchFetchCompanyCameras = (companyId) => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	return new Promise((resolve, reject) => {
		axiosInstance
			.get(GET_COMPANY_CAMERAS(companyId))
			.then((response) => {
				const data = response.data;
				dispatch(setActiveCompanyCameras(data));
				resolve(companyId);
			})
			.catch((err) => {
				const error = err.message;
				reject(error);
			})
			.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
	});
};

export const dispatchFetchCompanyAlertPhones =
	(companyId) => async (dispatch) => {
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		return new Promise((resolve, reject) => {
			axiosInstance
				.get(GET_COMPANY_ALERT_PHONES(companyId))
				.then((response) => {
					const data = response.data;
					dispatch(setActiveCompanyAlertPhones(data));
					resolve(data);
				})
				.catch((err) => {
					console.log(err);
					const error = err.message;
					reject(error);
				})
				.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
		});
	};

import React from 'react'
import { useDispatch } from 'react-redux'

import { deactivateModal } from '../../../../store/slices/modalSlice'
import InputField from '../../../../Components/UiComponents/InputField/InputField'

import './styles.newCompanyModal.css'

const NewCompanyModal = () => {

    const dispatch = useDispatch()

    return (
        <div className='edit-info-modal-wrapper'>
            <div className='edit-info-modal-form-wrapper'>
                <div className='edit-info-modal-header'>
                    <p className='edit-info-modal-title-txt'>New Company</p>
                    <p className='edit-info-modal-exit-txt' onClick={() => {
                        dispatch(deactivateModal());
                    }}>X</p>
                </div>
                <div className='new-company-modal-body'>
                    <p className='new-company-modal-normal-txt'>Enter your invite link here:</p>
                    <InputField placeholder='Link' type='text' />
                </div>
                <button className='edit-info-modal-button'>Go</button>
            </div>
        </div>
    )
}

export default NewCompanyModal
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AiFillMinusCircle } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";

import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import ClickAwayListener from "@mui/base/ClickAwayListener";

import {
	deleteAlertSilent,
	rejectAlertSilent,
	ApproveAlertSilent,
} from "./historyPage.actions";

import { formatDate } from "../../utils/helpers";
import { SILENT_ACTIONS } from "../../constants/app.constants";
import HistoryPageTableFilters from "./Components/HistoryPageTableFilters";
import HasPermissions from "../../Components/PermissionsFilters/HasPermissions";
import HistoryPageTriggerImageOverlay from "./Components/HistoryPageTriggerImageOverlay";
import ProgressiveImage from "../../Components/UiComponents/ImageLoaderAndWrapper/ProgressiveImage";

import "./historyPage.styles.css";

const tableHeadSx = {
	backgroundColor: "#23c8aa",
};

const activeAlertSx = {
	backgroundColor: "lightgray",
	cursor: "pointer",
};

const tableBodySx = {
	backgroundColor: "white",
};

const tableSx = {
	height: "max-content",
};

const rowSx = {
	cursor: "pointer",
};

const handleStatusStyle = (status) => {
	if (status === "PENDING") {
		return "history-page-table-row-txt-status-pending";
	} else if (status === "APPROVED") {
		return "history-page-table-row-txt-status-approved";
	} else {
		return "history-page-table-row-txt-status-declined";
	}
};

const HistoryPage = ({
	alerts,
	company,
	allAlerts,
	dataStateSilent,
	alertCameraNames,
}) => {
	const dispatch = useDispatch();

	const [activeAlert, setActiveAlert] = useState(null);
	const [activeAlertId, setActiveAlertId] = useState(null);
	const [imageOverlayOpen, setImageOverlayOpen] = useState(false);

	const handleImageOverlayClose = (e) => {
		e.stopPropagation();
		setImageOverlayOpen(false);
	};

	const handleTableClickAway = () => {
		setActiveAlertId(null);
		setActiveAlert(null);
	};

	return (
		<div className="history-page-container">
			<ClickAwayListener onClickAway={handleTableClickAway}>
				<div className="history-page-table-container">
					<div className="history-page-table-header-wrapper">
						<p className="history-page-table-header-title-txt">History log</p>
					</div>
					<HistoryPageTableFilters
						alerts={alerts}
						allAlerts={allAlerts}
						alertCameraNames={alertCameraNames}
					/>
					<div className="history-page-table-body-container">
						<div className="history-page-table-body-wrapper">
							<div className="history-page-table-holder">
								<div className="history-page-table-rows-container">
									{dataStateSilent.action ===
									SILENT_ACTIONS.HISTORY_PAGE_LOAD_ALERTS ? (
										<Fade
											in={
												dataStateSilent.action ===
												SILENT_ACTIONS.HISTORY_PAGE_LOAD_ALERTS
													? true
													: false
											}
											timeout={500}
										>
											<Stack
												spacing={1}
												alignItems="center"
												justifyContent="center"
											>
												<Skeleton
													variant="rectangular"
													width="90%"
													height={60}
												/>
												<Skeleton
													variant="rectangular"
													width="90%"
													height={60}
												/>
												<Skeleton
													variant="rectangular"
													width="90%"
													height={60}
												/>
												<Skeleton
													variant="rectangular"
													width="90%"
													height={60}
												/>
												<Skeleton
													variant="rectangular"
													width="90%"
													height={60}
												/>
											</Stack>
										</Fade>
									) : (
										<TableContainer component={Paper}>
											<Table aria-label="Alerts" sx={tableSx}>
												<TableHead sx={tableHeadSx}>
													<TableRow>
														<TableCell>
															<p className="history-page-table-header-txt">
																Camera
															</p>
														</TableCell>
														<TableCell>
															<p className="history-page-table-header-txt">
																Alert
															</p>
														</TableCell>
														<TableCell>
															<p className="history-page-table-header-txt">
																Time
															</p>
														</TableCell>
														<TableCell>
															<p className="history-page-table-header-txt">
																Status
															</p>
														</TableCell>
														<TableCell />
													</TableRow>
												</TableHead>
												<TableBody sx={tableBodySx}>
													{alerts.map((alert) => {
														return (
															<TableRow
																key={alert.id}
																onClick={() => {
																	setActiveAlertId(alert.id);
																	setActiveAlert(alert);
																}}
																sx={
																	activeAlertId === alert.id
																		? activeAlertSx
																		: rowSx
																}
															>
																<TableCell>
																	<p className="history-page-table-row-txt">
																		{alert.camera.name}
																	</p>
																</TableCell>
																<TableCell>
																	<p className="history-page-table-row-txt">
																		Smoke
																	</p>
																</TableCell>
																<TableCell>
																	<p className="history-page-table-row-txt">
																		{formatDate(alert.timestamp)}
																	</p>
																</TableCell>
																<TableCell>
																	<p
																		className={handleStatusStyle(alert.status)}
																	>
																		{alert.status}
																	</p>
																</TableCell>
																<TableCell>
																	<HasPermissions>
																		<div className="action-icons-container">
																			<Tooltip title="Delete">
																				<div
																					className="action-icon"
																					onClick={(e) => {
																						e.stopPropagation();
																						dispatch(
																							deleteAlertSilent(
																								alert.id,
																								company.id,
																							),
																						);
																					}}
																				>
																					<MdDeleteForever
																						size={25}
																						color="red"
																					/>
																				</div>
																			</Tooltip>
																			{alert.status === "PENDING" ? (
																				<>
																					<Tooltip title="Approve">
																						<div
																							className="action-icon"
																							onClick={(e) => {
																								e.stopPropagation();
																								dispatch(
																									ApproveAlertSilent(
																										alert.id,
																										company.id,
																									),
																								);
																							}}
																						>
																							<AiFillCheckCircle
																								size={25}
																								color="green"
																							/>
																						</div>
																					</Tooltip>
																					<Tooltip title="Reject">
																						<div
																							className="action-icon"
																							onClick={(e) => {
																								e.stopPropagation();
																								dispatch(
																									rejectAlertSilent(
																										alert.id,
																										company.id,
																									),
																								);
																							}}
																						>
																							<AiFillMinusCircle
																								size={25}
																								color="orange"
																							/>
																						</div>
																					</Tooltip>
																				</>
																			) : null}
																		</div>
																	</HasPermissions>
																</TableCell>
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
									)}
								</div>
							</div>

							<div className="history-page-table-rows-image-container">
								{dataStateSilent.action ===
								SILENT_ACTIONS.HISTORY_PAGE_LOAD_ALERTS ? (
									<Stack
										spacing={1}
										alignItems="center"
										justifyContent="center"
									>
										<Skeleton variant="rounded" width="90%" height={300} />
									</Stack>
								) : (
									<div className="history-page-trigger-image-container">
										<div className="history-page-trigger-image-header-container">
											<p className="history-page-table-header-txt">
												Trigger image
											</p>
										</div>
										{activeAlert ? (
											<div
												className="history-page-trigger-image-wrapper"
												onClick={() => setImageOverlayOpen(true)}
											>
												<p className="history-page-trigger-image-txt">
													{activeAlert.camera.name}
												</p>
												<ProgressiveImage src={activeAlert.image} alt="" />
												<p className="history-page-trigger-image-txt">
													Confidence: {activeAlert.confidence}%
												</p>
											</div>
										) : null}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</ClickAwayListener>
			{/* <div className="history-page-stats-container">
				<div className="history-page-graph-container">
					<HistoryPageGraph chartData={chartData} />
				</div>
				<div className="history-page-threats-container"></div>
				<div className="history-page-rate-container"></div>
			</div> */}
			<HistoryPageTriggerImageOverlay
				activeAlert={activeAlert}
				isOpen={imageOverlayOpen}
				handleClose={handleImageOverlayClose}
			/>
		</div>
	);
};

export default HistoryPage;

import { GiCancel } from "react-icons/gi";
import Tooltip from "@mui/material/Tooltip";
import { BiMessageAltError } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import {
	getCompanyAlertPhones,
	deleteCompanyAlertPhone,
} from "../../../../Components/AppBase/appBase.services";

import {
	DATA_STATE,
	MODAL_TYPE,
	ACTIVE_MODAL,
} from "../../../../constants/app.constants";

import {
	setDataState,
	displayNotification,
} from "../../../../store/slices/appStateSlice";

import {
	setActiveModal,
	setActiveModalPayload,
} from "../../../../store/slices/modalSlice";

import {
	setActiveCompanyAlertPhones,
	selectActiveCompanyAlertPhones,
} from "../../../../store/slices/userSlice";

import { testAlertPhone } from "./activity.actions";

import "./styles.activity.css";

const Activity = ({ user }) => {
	const dispatch = useDispatch();

	const alertPhones = useSelector(selectActiveCompanyAlertPhones);
	return (
		<div className="activity-component-wrapper">
			<p className="activity-title-txt">Your active alert phones</p>
			<div className="phone-box">
				{alertPhones
					? alertPhones.map((phone) => {
							return (
								<div key={phone.phone_number} className="phone-wrapper">
									<p className="phone-number">{phone.phone_number}</p>
									<p className="phone-number">
										{phone.contact_name === "N/A" ? "" : phone.contact_name}
									</p>
									<div className="actions-wrapper">
										<Tooltip title="Send a test message">
											<div
												className="phone-test-wrapper"
												onClick={async () => {
													await testAlertPhone(phone.phone_number).then(() => {
														dispatch(
															displayNotification({
																text: "Test message sent",
																type: MODAL_TYPE.SUCCESS,
															}),
														);
													});
												}}
											>
												<BiMessageAltError size={25} />
											</div>
										</Tooltip>
										<Tooltip title="Delete phone">
											<div
												className="phone-delete"
												onClick={async () => {
													dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
													let phoneDeleted = await deleteCompanyAlertPhone(
														phone.id,
													);
													if (phoneDeleted.error === undefined) {
														let activeCompanyAlertPhones =
															await getCompanyAlertPhones(phone.company);
														if (activeCompanyAlertPhones.data)
															dispatch(
																setActiveCompanyAlertPhones(
																	activeCompanyAlertPhones.data,
																),
															);
														dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
													}
												}}
											>
												<GiCancel size={25} />
											</div>
										</Tooltip>
									</div>
								</div>
							);
					  })
					: null}
			</div>
			<button
				className="button-phone"
				onClick={() => {
					dispatch(setActiveModalPayload(user));
					dispatch(setActiveModal(ACTIVE_MODAL.ADD_NEW_ALERT_PHONE));
				}}
			>
				Add new phone
			</button>
		</div>
	);
};

export default Activity;

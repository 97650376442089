import { Grow } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableRowsIcon from "@mui/icons-material/TableRows";
import SsidChartIcon from "@mui/icons-material/SsidChart";

import {
	setActiveHistoryPageComponent,
	selectActiveHistoryPageComponent,
} from "../../../../store/slices/routingSlice";

export const HISTORY_PAGE_COMPONENT = {
	TABLE: "/history",
	GRAPH: "/history/graph",
};

const HistoryPageGraphOption = () => {
	const dispatch = useDispatch();
	const activeHistoryPageComponent = useSelector(
		selectActiveHistoryPageComponent,
	);

	const [animate, setAnimate] = useState(true);

	useEffect(() => {
		return () => {
			setAnimate(false);
		};
	}, []);

	const resolveColor = (requiredPathname) => {
		return activeHistoryPageComponent === requiredPathname
			? "#23c8aa"
			: "white";
	};

	return (
		<Grow in={animate}>
			<div className="suboption-wrapper">
				<div
					className="suboption-container-second"
					onClick={() =>
						dispatch(
							setActiveHistoryPageComponent(HISTORY_PAGE_COMPONENT.TABLE),
						)
					}
				>
					<TableRowsIcon fontSize="large" color="colorWhite" />
					<p
						style={{
							color: resolveColor(HISTORY_PAGE_COMPONENT.TABLE),
							fontSize: "large",
							fontFamily: '"Nunito", sans-serif',
							margin: 0,
						}}
					>
						Table
					</p>
				</div>
				<div
					className="suboption-container-second"
					onClick={() =>
						dispatch(
							setActiveHistoryPageComponent(HISTORY_PAGE_COMPONENT.GRAPH),
						)
					}
				>
					<SsidChartIcon fontSize="large" color="colorWhite" />
					<p
						style={{
							color: resolveColor(HISTORY_PAGE_COMPONENT.GRAPH),
							fontSize: "large",
							fontFamily: '"Nunito", sans-serif',
							margin: 0,
						}}
					>
						Graph
					</p>
				</div>
			</div>
		</Grow>
	);
};

export default HistoryPageGraphOption;

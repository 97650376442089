import React from "react";
import CameraHistory from "./Components/CameraHistory/CameraHistory";

import "./styles.css";

const CameraHistoryList = ({ activeCompanyCameras }) => {
  if (!activeCompanyCameras) return null;
  return (
    <div className="camera-history-list-body">
      {activeCompanyCameras.map((camera) => {
        return <CameraHistory camera={camera} key={camera.id} />;
      })}
    </div>
  );
};

export default CameraHistoryList;

import MainPageHeader from './MainPageHeader/MainPageHeader'
import MainPageContent from './MainPageContent/MainPageContent'
import MainPageSideMenu from './MainPageSideMenu/MainPageSideMenu'

import './main-page-styles.css'

const MainPage = () => {
    return (
        <div className='app-container'>
            <MainPageHeader />
            <MainPageSideMenu />
            <MainPageContent />
        </div>
    )
}

export default MainPage
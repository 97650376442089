import React from "react";
// import { useDispatch } from "react-redux";

// import { ACTIVE_MODAL } from "../../../../constants/app.constants";
// import { setActiveModal } from "../../../../store/slices/modalSlice";

import "./styles.personalInfo.css";

const PersonalInfo = ({ user }) => {
	// const dispatch = useDispatch();

	return (
		<div className="personal-info-component-wrapper">
			{/* <div className='personal-info-photo-section-wrapper'>
                <div className='photo-wrapper'>
                    <img src={ProfileImage} alt="" className='profile-image' />
                </div>
            </div> */}
			<div className="personal-info-wrapper">
				<div>
					<p className="subtitle-txt">Personal Info</p>
					<div className="name-wrapper">
						<div className="first-name-wrapper">
							<p className="normal-txt">First name:</p>
							<p className="field-txt">{user.first_name}</p>
						</div>
						<div className="last-name-wrapper">
							<p className="normal-txt">Last Name:</p>
							<p className="field-txt">{user.last_name}</p>
						</div>
					</div>
				</div>
				<div>
					<p className="subtitle-txt">Contact Info</p>
					<div className="contact-info-wrapper">
						<div className="phone-number-wrapper">
							<p className="normal-txt">Phone number:</p>
							<p className="field-txt">{user.phone_number}</p>
						</div>
						<div className="phone-number-wrapper">
							<p className="normal-txt">Email:</p>
							<p className="field-txt">{user.email}</p>
						</div>
					</div>
				</div>

				{/* <button
					className="button"
					onClick={() => {
						dispatch(setActiveModal(ACTIVE_MODAL.UPDATE_PROFILE_INFO));
					}}
				>
					Make changes
				</button> */}
			</div>
		</div>
	);
};

export default PersonalInfo;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { AiFillCheckCircle, AiFillMinusCircle } from "react-icons/ai";

import {
	Table,
	Paper,
	styled,
	Tooltip,
	TableBody,
	TableCell,
	TableHead,
	TableContainer,
} from "@mui/material";

import { formatDate } from "../../../../../../utils/helpers";
import { approveAlert, deleteAlert, rejectAlert } from "../camerasList.actions";
import HasPermissions from "../../../../../../Components/PermissionsFilters/HasPermissions";
import HistoryPageTriggerImageOverlay from "../../../../../HistoryPage/Components/HistoryPageTriggerImageOverlay";

const tableHeadSx = {
	backgroundColor: "#23c8aa",
	borderRadius: "0",
};

const tableBodySx = {
	backgroundColor: "white",
};

const tableSx = {
	height: "max-content",
};

const CamerasListDetailedTableActiveLogs = ({ alerts, company, cameraId }) => {
	const StyledTableRow = styled(TableRow)(() => ({
		[`&.${tableRowClasses.root}`]: {
			height: "75px",
		},
	}));

	const dispatch = useDispatch();

	const [activeAlert, setActiveAlert] = useState(null);
	const [imageOverlayOpen, setImageOverlayOpen] = useState(false);

	const handleImageOverlayClose = (e) => {
		e.stopPropagation();
		setImageOverlayOpen(false);
	};

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="Alerts" sx={tableSx}>
					<TableHead sx={tableHeadSx}>
						<TableRow>
							<TableCell>
								<p className="history-page-table-header-txt">Alert</p>
							</TableCell>
							<TableCell>
								<p className="history-page-table-header-txt">Time</p>
							</TableCell>
							<TableCell>
								<div className="trigger-image-container">
									<p className="history-page-table-header-txt">Trigger image</p>
								</div>
							</TableCell>
							<TableCell>
								<p className="history-page-table-header-txt">Action</p>
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody sx={tableBodySx}>
						{alerts?.map((alert) => {
							return (
								<StyledTableRow key={alert.id}>
									<TableCell>
										<p className="history-page-table-row-txt">Smoke</p>
									</TableCell>
									<TableCell>
										<p className="history-page-table-row-txt">
											{formatDate(alert.timestamp)}
										</p>
									</TableCell>
									<TableCell>
										<div
											className="trigger-image-container"
											onClick={() => {
												setActiveAlert(alert);
												setImageOverlayOpen(true);
											}}
										>
											<img
												className="trigger-image"
												src={alert.image}
												alt="trigger"
											/>
										</div>
									</TableCell>
									<TableCell>
										<HasPermissions>
											<div className="action-icons-container">
												<Tooltip title="Delete">
													<div
														className="action-icon"
														onClick={(e) => {
															e.stopPropagation();
															dispatch(
																deleteAlert(alert.id, cameraId, company.id),
															);
														}}
													>
														<MdDeleteForever size={25} color="red" />
													</div>
												</Tooltip>
												{alert.status === "PENDING" ? (
													<>
														<Tooltip title="Approve">
															<div
																className="action-icon"
																onClick={(e) => {
																	e.stopPropagation();
																	dispatch(
																		approveAlert(
																			alert.id,
																			cameraId,
																			company.id,
																		),
																	);
																}}
															>
																<AiFillCheckCircle size={25} color="green" />
															</div>
														</Tooltip>
														<Tooltip title="Reject">
															<div
																className="action-icon"
																onClick={(e) => {
																	e.stopPropagation();
																	dispatch(
																		rejectAlert(alert.id, cameraId, company.id),
																	);
																}}
															>
																<AiFillMinusCircle size={25} color="orange" />
															</div>
														</Tooltip>
													</>
												) : null}
											</div>
										</HasPermissions>
									</TableCell>
								</StyledTableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<HistoryPageTriggerImageOverlay
				activeAlert={activeAlert}
				isOpen={imageOverlayOpen}
				handleClose={handleImageOverlayClose}
			/>
		</>
	);
};

export default CamerasListDetailedTableActiveLogs;

import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	palette: {
		cameraAlert: {
			main: "#FFA500",
		},
		mainGreen: {
			main: "#23c8aa",
		},
		colorWhite: {
			main: "#FFFFFF",
		},
	},
});

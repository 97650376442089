import React from "react";

import { connect } from "react-redux";
import { ACTIVE_MODAL } from "../../constants/app.constants";
import EditInfoModal from "../../Pages/ProfilePage/components/EditInfoModal/EditInfoModal";
import NewCompanyModal from "../../Pages/ProfilePage/components/NewCompanyModal/NewCompanyModal";
import WeatherModal from "../../Pages/MainPage/CamerasPage/Components/WeatherModal.js/WeatherModal";
import EditCameraModal from "../../Pages/MainPage/CamerasPage/Components/EditCameraModal/EditCameraModal";
import AddAlertPhoneModal from "../../Pages/ProfilePage/components/AddAlertPhoneModal/AddAlertPhoneModal";
import CameraStreamModal from "../../Pages/MainPage/CamerasPage/Components/CameraStreamModal/CameraStreamModal";
import AddNewCameraModal from "../../Pages/MainPage/CamerasPage/Components/AddNewCameraModal.js/AddNewCameraModal";
import DemoCameraStreamModal from "../../Pages/MainPage/CamerasPage/Components/DemoCameraStreamModal.js/DemoCameraStreamModal.js";

const ModalController = ({ activeModal }) => {
	switch (activeModal) {
		case ACTIVE_MODAL.UPDATE_PROFILE_INFO:
			return <EditInfoModal />;
		case ACTIVE_MODAL.NEW_COMPANY_ADD:
			return <NewCompanyModal />;
		case ACTIVE_MODAL.ADD_NEW_CAMERA:
			return <AddNewCameraModal />;
		case ACTIVE_MODAL.CAMERA_STREAM:
			return <CameraStreamModal />;
		case ACTIVE_MODAL.ADD_NEW_ALERT_PHONE:
			return <AddAlertPhoneModal />;
		case ACTIVE_MODAL.DEMO_CAMERA_STREAM:
			return <DemoCameraStreamModal />;
		case ACTIVE_MODAL.WEATHER_MODAL:
			return <WeatherModal />;
		case ACTIVE_MODAL.EDIT_CAMERA:
			return <EditCameraModal />;
		default:
			return null;
	}
};

const mapStateToProps = (state) => ({
	activeModal: state.activeModal.activeModal,
});

export default connect(mapStateToProps)(ModalController);

import React, { useState } from "react";

import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-number-input";

import logo from "../../assets/logo.png";
import { registerUser } from "./signupPage.actions";
import { DATA_STATE } from "../../constants/app.constants";
import InputField from "../../Components/UiComponents/InputField/InputField";
import {
	displayNotification,
	setDataState,
} from "../../store/slices/appStateSlice";

import "./styles.css";

const SignupPage = () => {
	const dispatch = useDispatch();

	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [surname, setSurname] = useState();
	const [company] = useState();
	const [password, setPassword] = useState();
	const [phoneNumber, setPhoneNumber] = useState();
	const [repeatPassword, setRepeatPassword] = useState();

	const [tocAgree, setTocAgree] = useState(false);
	const [subscribe, setSubscribe] = useState(false);

	const handleSubscribe = (event) => {
		setSubscribe(event.target.checked);
	};

	const handleTocAgree = (event) => {
		setTocAgree(event.target.checked);
	};

	const handleFirstNameChange = (event) => {
		setName(event.target.value);
	};

	const handleSurnameChange = (event) => {
		setSurname(event.target.value);
	};

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

	const handleRepeatPasswordChange = (event) => {
		setRepeatPassword(event.target.value);
	};

	return (
		<div className="login-page-container">
			<div className="login-form-container">
				<div className="login-form-logo-container">
					<img src={logo} alt="" className="login-form-logo" />
				</div>
				<div className="login-form-welcome-container">
					<p className="login-form-welcome-title-txt">Hello,</p>
					<p className="login-form-welcome-subtitle-txt">let's get started!</p>
				</div>
				<div className="login-form-input-container">
					<div className="login-form-line-double-container">
						<div className="login-form-line-double-input-wrapper">
							<InputField
								placeholder="Your name"
								onChange={handleFirstNameChange}
								type="text"
							/>
						</div>
						<div className="login-form-line-double-input-wrapper">
							<InputField
								placeholder="Your surname"
								onChange={handleSurnameChange}
								type="text"
							/>
						</div>
					</div>
					<div className="login-form-line-container">
						<InputField
							placeholder="Your email"
							onChange={handleEmailChange}
							type="text"
						/>
					</div>
					<div className="login-form-line-container">
						<PhoneInput
							placeholder="Enter phone number"
							value={phoneNumber}
							onChange={setPhoneNumber}
						/>
					</div>
					<div className="login-form-line-container">
						<InputField
							placeholder="Password"
							onChange={handlePasswordChange}
							type="password"
						/>
					</div>
					<div className="login-form-line-container">
						<InputField
							placeholder="Repeat your password"
							onChange={handleRepeatPasswordChange}
							type="password"
						/>
					</div>
				</div>
				<div className="login-form-options-container">
					<div className="login-form-options-actions-container">
						<Checkbox checked={subscribe} onChange={handleSubscribe} />
						<p className="login-form-input-buttons-txt">
							I want to subscribe to the newsletter
						</p>
					</div>
					<div className="login-form-options-actions-container">
						<Checkbox checked={tocAgree} onChange={handleTocAgree} />
						<p className="login-form-input-buttons-txt">
							I agree to the terms and conditions
						</p>
					</div>
					<div className="login-form-options-button-container">
						<button
							className="login-form-input-login-button"
							onClick={async () => {
								dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
								let user = {
									first_name: name,
									last_name: surname,
									email,
									password,
									repeatPassword,
									company,
									phone_number: phoneNumber,
								};
								if (!tocAgree) {
									dispatch(
										displayNotification(
											"You must agree to the Terms and Conditions",
										),
									);
									dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
									return;
								}
								let signupData = await registerUser(user);
								if (signupData.error) {
									dispatch(
										displayNotification(
											Object.values(signupData.error.response.data)[0][0],
										),
									);
								}
								dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
							}}
						>
							Next
						</button>
					</div>
				</div>
			</div>
			<div className="login-quotes-container"></div>
		</div>
	);
};

export default SignupPage;

import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import WeatherIcon from "./components/WeatherIcon";
import useFetch from "../../../../../hooks/useFetch";
import { checkIfIconActive } from "./weatherModal.helpers";
import { GET_WEATHER_DATA } from "../../../../../constants/url";
import { selectActiveModalProps } from "../../../../../store/slices/modalSlice";

import ModalContainer from "../../../../../Components/UiComponents/ModalContainer/ModalContainer";
import clearsky from "../../../../../assets/weather icons/clear-sky.png";
import fewclouds from "../../../../../assets/weather icons/few-clouds.png";
import menuweather from "../../../../../assets/weather icons/menu-weather.png";
import brokenclouds from "../../../../../assets/weather icons/broken-clouds.png";
import showerrain from "../../../../../assets/weather icons/shower-rain.png";
import rain from "../../../../../assets/weather icons/rain.png";
import thunderstorm from "../../../../../assets/weather icons/thunderstorm.png";
import snow from "../../../../../assets/weather icons/snow.png";
import mist from "../../../../../assets/weather icons/mist.png";

import "./weatherModal.styles.css";

const WeatherModal = () => {
	const modalProps = useSelector(selectActiveModalProps);
	const { data, loading } = useFetch(GET_WEATHER_DATA(modalProps));

	return (
		<ModalContainer title="Weather for camera location">
			{loading ? (
				<div className="weather-modal-loading-container">
					<CircularProgress color="primary" size={60} thickness={5} />
				</div>
			) : (
				<div className="weather-modal-body-container">
					<div className="weather-modal-title-container">
						<p className="weather-modal-title-txt">{`${data.name} | ${data.sys.country}`}</p>
					</div>
					<div className="weather-modal-body-wrapper">
						<div className="weather-modal-icons-container">
							<div className="weather-modal-icons-row-container">
								<WeatherIcon
									title="Clear sky"
									image={clearsky}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"clearsky",
									)}
								/>
								<WeatherIcon
									title="Few clouds"
									image={fewclouds}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"fewclouds",
									)}
								/>
								<WeatherIcon
									title="Cloudy"
									image={menuweather}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"menuweather",
									)}
								/>
							</div>
							<div className="weather-modal-icons-row-container">
								<WeatherIcon
									title="Broken clouds"
									image={brokenclouds}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"brokenclouds",
									)}
								/>
								<WeatherIcon
									title="Shower rain"
									image={showerrain}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"showerrain",
									)}
								/>
								<WeatherIcon
									title="Rain"
									image={rain}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"rain",
									)}
								/>
							</div>
							<div className="weather-modal-icons-row-container">
								<WeatherIcon
									title="Thunderstorm"
									image={thunderstorm}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"thunderstorm",
									)}
								/>
								<WeatherIcon
									title="Snow"
									image={snow}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"snow",
									)}
								/>
								<WeatherIcon
									title="Mist"
									image={mist}
									active={checkIfIconActive(
										data.weather[0].icon,
										data.weather[0].main,
										"mist",
									)}
								/>
							</div>
						</div>
						<div className="weather-modal-info-container">
							<div className="weather-modal-info-row">
								<div className="weather-modal-info-def">
									<p className="weather-modal-info-def-txt">temp</p>
								</div>
								<div className="weather-modal-info-value">
									<p className="weather-modal-info-def-txt">
										{Math.round(data.main.temp)}
									</p>
								</div>
							</div>
							<div className="weather-modal-info-row">
								<div className="weather-modal-info-def">
									<p className="weather-modal-info-def-txt">feels like</p>
								</div>
								<div className="weather-modal-info-value">
									<p className="weather-modal-info-def-txt">
										{Math.round(data.main.feels_like)}
									</p>
								</div>
							</div>
							<div className="weather-modal-info-row">
								<div className="weather-modal-info-def">
									<p className="weather-modal-info-def-txt">pressure</p>
								</div>
								<div className="weather-modal-info-value">
									<p className="weather-modal-info-def-txt">
										{Math.round(data.main.pressure)}
									</p>
								</div>
							</div>
							<div className="weather-modal-info-row">
								<div className="weather-modal-info-def">
									<p className="weather-modal-info-def-txt">humidity</p>
								</div>
								<div className="weather-modal-info-value">
									<p className="weather-modal-info-def-txt">
										{Math.round(data.main.humidity)}
									</p>
								</div>
							</div>
							<div className="weather-modal-info-row">
								<div className="weather-modal-info-def">
									<p className="weather-modal-info-def-txt">visibility</p>
								</div>
								<div className="weather-modal-info-value">
									<p className="weather-modal-info-def-txt">
										{Math.round(data.visibility)}
									</p>
								</div>
							</div>
							<div className="weather-modal-info-row">
								<div className="weather-modal-info-def">
									<p className="weather-modal-info-def-txt">wind speed</p>
								</div>
								<div className="weather-modal-info-value">
									<p className="weather-modal-info-def-txt">
										{Math.round(data.wind.speed)}
									</p>
								</div>
							</div>
							<div className="weather-modal-info-row">
								<div className="weather-modal-info-def">
									<p className="weather-modal-info-def-txt">wind deg</p>
								</div>
								<div className="weather-modal-info-value">
									<p className="weather-modal-info-def-txt">
										{Math.round(data.wind.deg)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</ModalContainer>
	);
};

export default WeatherModal;

import axios from "axios"
import { REGISTER_USER } from "../../constants/url";

export const registerUser = async (user) => {
    let data;
    let error;

    await axios
        .post(REGISTER_USER, user)
        .then(response => {
            data = response.data
        })
        .catch(err => {
            error = err
        })

    return { data, error }
}
import { useDispatch, useSelector } from "react-redux";

import {
	setActiveCameraForDetailedList,
	selectActiveCameraForDetailedList,
} from "../../../../../store/slices/routingSlice";

import CameraCard from "../CameraCard/CameraCard";
import { CAMERAS_LIST_ACTIONS } from "./camerasList.actions";
import { selectDataState } from "../../../../../store/slices/appStateSlice";
import CamerasListDetailedLogsContainer from "./CamerasListDetailedLogsContainer";

import "./styles.css";

const CamerasListDetailed = ({ activeCompanyCameras }) => {
	const dispatch = useDispatch();
	const selectedCamera = useSelector(selectActiveCameraForDetailedList);

	const dataState = useSelector(selectDataState);

	if (activeCompanyCameras)
		return (
			<div className="detailed-cameras-list-body">
				<div className="detailed-cameras-list-left">
					{activeCompanyCameras.map((camera) => {
						return (
							<div
								key={camera.id}
								style={{
									backgroundColor:
										selectedCamera === camera.id ? "rgb(173, 174, 177)" : null,
								}}
								className="detailed-cameras-list-card-container"
								onClick={() =>
									dispatch(setActiveCameraForDetailedList(camera.id))
								}
							>
								<CameraCard
									camera={camera}
									dataState={dataState}
									dataStateWatcher={CAMERAS_LIST_ACTIONS.FETCH_COMPANY_CAMERAS}
									showInfo={selectedCamera === camera.id ? true : false}
								/>
							</div>
						);
					})}
				</div>
				<div className="detailed-cameras-list-right">
					{selectedCamera ? (
						<CamerasListDetailedLogsContainer selectedCamera={selectedCamera} />
					) : (
						<div className="detailed-cameras-list-no-selection-container">
							<p className="camera-card-txt">
								Select a camera to display alert info
							</p>
						</div>
					)}
				</div>
			</div>
		);
};

export default CamerasListDetailed;

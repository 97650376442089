import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { deactivateModal } from "../../../store/slices/modalSlice";

import "./modalContainer.styles.css";

const iconSx = {
	color: "white",
};

const ModalContainer = ({ children, title, width }) => {
	const dispatch = useDispatch();
	const [load, setLoad] = useState(null);

	useEffect(() => {
		setLoad(true);

		return () => {
			setLoad(false);
		};
	}, []);

	return (
		<Fade in={load} exit={true} timeout={300} unmountOnExit>
			<div className="modal-container-wrapper">
				<div
					style={{ width: width ? `${width}` : "none" }}
					className="modal-container-form-wrapper"
				>
					<div className="modal-container-header">
						<p className="modal-container-title-txt">{title}</p>

						<Tooltip title="Close">
							<div className="modal-container-exit-txt">
								<IconButton
									aria-label="Close"
									sx={iconSx}
									size="large"
									onClick={() => {
										dispatch(deactivateModal());
									}}
								>
									<CancelIcon fontSize="inherit" color="colorWhite" />
								</IconButton>
							</div>
						</Tooltip>
					</div>
					<div className="modal-container-body">{children}</div>
				</div>
			</div>
		</Fade>
	);
};

export default ModalContainer;

import { configureStore } from "@reduxjs/toolkit";

import userReducer from './slices/userSlice'
import appStateReducer from './slices/appStateSlice'
import activeModalReducer from './slices/modalSlice'
import activeRouteReducer from './slices/routingSlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        appState: appStateReducer,
        activeModal: activeModalReducer,
        activeRoute: activeRouteReducer,
    },
})
import { useSelector } from "react-redux";

import { selectUser } from "../../store/slices/userSlice";

const HasPermissions = ({ children }) => {
	const user = useSelector(selectUser);

	if (user) {
		if (user.is_admin) {
			return children;
		} else {
			return null;
		}
	}
};

export default HasPermissions;

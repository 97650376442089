import {
	setDataState,
	displayNotification,
} from "../../../../../store/slices/appStateSlice";

import { sleep } from "../../../../../utils/helpers";
import axiosInstance from "../../../../../utils/axios";
import { deactivateModal } from "../../../../../store/slices/modalSlice";
import { DELETE_CAMERA, EDIT_CAMERA } from "../../../../../constants/url";
import { fetchCompanyCameras } from "../../../../../store/slices/userSlice";
import { DATA_STATE, MODAL_TYPE } from "../../../../../constants/app.constants";

export const deleteCamera = (camera) => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	await sleep(2000);
	await axiosInstance
		.delete(DELETE_CAMERA(camera.id))
		.then(() => {
			const payload = {
				text: "Camera deleted successfully",
				type: MODAL_TYPE.SUCCESS,
			};
			dispatch(displayNotification(payload));
		})
		.catch(() => {
			const payload = {
				text: "Error deleting camera",
				type: MODAL_TYPE.FAILURE,
			};
			dispatch(displayNotification(payload));
		})
		.finally(() => {
			dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
			dispatch(deactivateModal());
			dispatch(fetchCompanyCameras(camera.company));
		});
};

export const editCamera = (camera) => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	await axiosInstance
		.put(EDIT_CAMERA(camera.id), camera)
		.then(() => {
			const payload = {
				text: "Changes saved successfully",
				type: MODAL_TYPE.SUCCESS,
			};
			dispatch(displayNotification(payload));
		})
		.catch(() => {
			const payload = {
				text: "Error editing camera",
				type: MODAL_TYPE.FAILURE,
			};
			dispatch(displayNotification(payload));
		})
		.finally(() => {
			dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
			dispatch(deactivateModal());
			dispatch(fetchCompanyCameras(camera.company));
		});
};

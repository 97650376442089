import { useDispatch, connect, useSelector } from "react-redux";

import CamerasList from "./Components/CamerasList/CamerasList";
import { ACTIVE_MODAL } from "../../../constants/app.constants";
import { setActiveModal } from "../../../store/slices/modalSlice";
import { selectActiveCameraView } from "../../../store/slices/routingSlice";
import CameraHistoryList from "./Components/CameraHistory/CameraHistoryList";
import HasPermissions from "../../../Components/PermissionsFilters/HasPermissions";
import { CAMERA_LIST_VIEWS } from "../MainPageSideMenu/Components/CamerasListSwitch";

import "./styles.css";

const CamerasPage = ({ activeCompanyCameras }) => {
	const dispatch = useDispatch();
	const activeCameraView = useSelector(selectActiveCameraView);

	return (
		<div className="cameras-page-body">
			<div className="cameras-page-left-side-container">
				<div className="cameras-page-cameras-container">
					<CamerasList activeCompanyCameras={activeCompanyCameras} />
				</div>
			</div>
			{activeCameraView === CAMERA_LIST_VIEWS.DETAILED ? null : (
				<div className="cameras-page-right-side-container">
					<div className="cameras-page-cameras-history-list-container">
						<CameraHistoryList activeCompanyCameras={activeCompanyCameras} />
					</div>
					<div className="cameras-page-new-camera-container">
						<HasPermissions>
							<button
								className="button-body-add-new-camera"
								onClick={() => {
									dispatch(setActiveModal(ACTIVE_MODAL.ADD_NEW_CAMERA));
								}}
							>
								Add new camera
							</button>
						</HasPermissions>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	activeCompanyCameras: state.user.activeCompanyCameras,
});

export default connect(mapStateToProps)(CamerasPage);

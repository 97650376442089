import React, { useState } from "react";
import { CircularProgress } from "@mui/material";

import "./progressiveImage.styles.css";

const ProgressiveImage = ({ src, stopStream, imgLoading, ...props }) => {
	const [error] = useState(false);

	// useEffect(() => {
	//     const img = new Image()
	//     img.src = src;
	//     img.onerror = () => {
	//         console.log('error')
	//         setError(true)
	//     }
	// }, [src])

	if (imgLoading)
		return (
			<div className="loading-wrapper">
				<CircularProgress />
			</div>
		);

	if (error)
		return (
			<div className="loading-wrapper">
				<p className="normal-txt">No preview available</p>
			</div>
		);

	return (
		<img
			{...{ src: src, ...props }}
			alt={props.alt || ""}
			className="camera-preview-image"
		/>
	);
};

export default ProgressiveImage;

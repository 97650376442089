import Map from "../../Map/Map";

const MapFormik = ({ field, form, camera }) => {
	const { name } = field;
	const { setFieldValue, getFieldMeta, setFieldError } = form;
	const { error } = getFieldMeta(name);

	const onChangeHandler = (value) => {
		setFieldValue(name, value);
	};

	return (
		<Map
			error={error}
			camera={camera}
			fieldName={name}
			setFieldError={setFieldError}
			onChangeHandler={onChangeHandler}
		/>
	);
};

export default MapFormik;

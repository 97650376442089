import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { MODAL_TYPE } from "../../constants/app.constants";
import { removeNotification } from "../../store/slices/appStateSlice";

import "./styles.css";

const NotificationContainer = ({ toDisplay, text, type }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		let timeout;
		if (toDisplay === true) {
			timeout = setTimeout(() => {
				dispatch(removeNotification());
			}, 3000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [toDisplay, dispatch]);

	if (toDisplay === true && type === MODAL_TYPE.SUCCESS) {
		return (
			<div className="notifications-container">
				<div className="notifications-container-component-wrapper-success">
					<p className="notifications-container-txt">{text}</p>
				</div>
			</div>
		);
	}

	if (toDisplay === true && type === MODAL_TYPE.FAILURE) {
		return (
			<div className="notifications-container">
				<div className="notifications-container-component-wrapper-failure">
					<p className="notifications-container-txt">{text}</p>
				</div>
			</div>
		);
	}

	return (
		<>
			{toDisplay === true ? (
				<div className="notifications-container-component-wrapper">
					<p className="notifications-container-txt">{text}</p>
				</div>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => ({
	toDisplay: state.appState.notification.displayNotification,
	text: state.appState.notification.notificationText,
	type: state.appState.notification.notificationType,
});

export default connect(mapStateToProps)(NotificationContainer);

import { useMemo } from "react";
import { Button } from "@mui/material";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { deleteCamera, editCamera } from "./editCameraModal.actions";
import { selectActiveModalProps } from "../../../../../store/slices/modalSlice";
import MapFormik from "../../../../../Components/UiComponents/FormikComponents/MapFormik";
import ModalContainer from "../../../../../Components/UiComponents/ModalContainer/ModalContainer";
import InputAreaFormik from "../../../../../Components/UiComponents/FormikComponents/InputAreaFormik";
import InputFieldFormik from "../../../../../Components/UiComponents/FormikComponents/InputFieldFormik";

import "./editCameraModal.styles.css";

const buttonSx = {
	backgroundColor: "#213a44",
	width: "10%",
};

const deleteBtnSx = {
	width: "10%",
};

const FORM_FIELDS = {
	NAME: "name",
	IP_ADDRESS: "ip_address",
	USERNAME: "username",
	PASSWORD: "password",
	DESCRIPTION: "description",
	COORDS: "coords",
	LAT: "lat",
	LON: "lon",
	COMPANY_ID: "company",
	CAMERA_ID: "id",
};

const EditCameraModal = () => {
	const dispatch = useDispatch();
	const { camera } = useSelector(selectActiveModalProps);

	const initialValues = useMemo(() => {
		return {
			[FORM_FIELDS.NAME]: camera.name,
			[FORM_FIELDS.IP_ADDRESS]: camera.ip_address,
			[FORM_FIELDS.USERNAME]: camera.username,
			[FORM_FIELDS.PASSWORD]: camera.password,
			[FORM_FIELDS.DESCRIPTION]: camera.description,
			[FORM_FIELDS.COORDS]: {
				lat: camera.lat,
				lng: camera.lon,
			},
			[FORM_FIELDS.LAT]: null,
			[FORM_FIELDS.LON]: null,
			[FORM_FIELDS.COMPANY_ID]: camera.company,
			[FORM_FIELDS.CAMERA_ID]: camera.id,
		};
	}, [camera]);

	const onSubmit = (formValues) => {
		const newCamera = {
			[FORM_FIELDS.LAT]: formValues.coords.lat,
			[FORM_FIELDS.LON]: formValues.coords.lng,
			[FORM_FIELDS.NAME]: formValues[FORM_FIELDS.NAME],
			[FORM_FIELDS.IP_ADDRESS]: formValues[FORM_FIELDS.IP_ADDRESS],
			[FORM_FIELDS.USERNAME]: formValues[FORM_FIELDS.USERNAME],
			[FORM_FIELDS.PASSWORD]: formValues[FORM_FIELDS.PASSWORD],
			[FORM_FIELDS.DESCRIPTION]: formValues[FORM_FIELDS.DESCRIPTION],
			[FORM_FIELDS.COMPANY_ID]: camera.company,
			[FORM_FIELDS.CAMERA_ID]: camera.id,
		};
		dispatch(editCamera(newCamera));
	};

	const onDelete = () => {
		dispatch(deleteCamera(camera));
	};

	if (!camera) return null;
	return (
		<ModalContainer title="Edit camera" width="60%">
			<Formik initialValues={initialValues}>
				{({ values }) => (
					<Form>
						<div className="edit-camera-modal-container">
							<div className="edit-camera-modal-form-container">
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">Name: </p>
									<Field name={FORM_FIELDS.NAME}>
										{({ form, ...formik }) => (
											<InputFieldFormik form={form} {...formik} />
										)}
									</Field>
								</div>
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">IP Address:</p>
									<Field name={FORM_FIELDS.IP_ADDRESS}>
										{({ form, ...formik }) => (
											<InputFieldFormik form={form} {...formik} />
										)}
									</Field>
								</div>
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">Username: </p>
									<Field name={FORM_FIELDS.USERNAME}>
										{({ form, ...formik }) => (
											<InputFieldFormik form={form} {...formik} />
										)}
									</Field>
								</div>
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">Password: </p>
									<Field name={FORM_FIELDS.PASSWORD}>
										{({ form, ...formik }) => (
											<InputFieldFormik
												type="password"
												form={form}
												{...formik}
											/>
										)}
									</Field>
								</div>
								<div className="add-new-camera-modal-input-container">
									<p className="add-new-camera-modal-normal-txt">
										Description:
									</p>
									<Field name={FORM_FIELDS.DESCRIPTION}>
										{({ form, ...formik }) => (
											<InputAreaFormik form={form} {...formik} />
										)}
									</Field>
								</div>
							</div>
							<div className="edit-camera-modal-map-container">
								<Field name={FORM_FIELDS.COORDS}>
									{({ form, ...formik }) => (
										<MapFormik form={form} {...formik} camera={camera} />
									)}
								</Field>
							</div>
						</div>
						<div className="edit-camera-modal-footer">
							<Button
								variant="contained"
								sx={buttonSx}
								onClick={() => onSubmit(values)}
							>
								<p className="history-page-filters-button-txt">Save</p>
							</Button>
							<Button
								variant="outlined"
								color="error"
								sx={deleteBtnSx}
								onClick={() => onDelete(camera.id)}
							>
								<p className="edit-camera-delete-btn">Delete</p>
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</ModalContainer>
	);
};

export default EditCameraModal;

import "./styles.css";

const MainPageSideMenuOption = ({ option, icon, activelink }) => {
	return (
		<div className="side-menu-options-box">
			<div className="side-menu-options-icon">{icon}</div>
			<p
				style={{
					color: activelink ? "#23c8aa" : "white",
					fontSize: "x-large",
					fontFamily: '"Nunito", sans-serif',
					margin: 0,
				}}
			>
				{option.text}
			</p>
		</div>
	);
};

export default MainPageSideMenuOption;

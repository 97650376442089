import Backdrop from "@mui/material/Backdrop";

import ProgressiveImage from "../../../Components/UiComponents/ImageLoaderAndWrapper/ProgressiveImage";

const HistoryPageTriggerImageOverlay = ({
	isOpen,
	activeAlert,
	handleClose,
}) => {
	if (activeAlert) {
		return (
			<Backdrop
				sx={{ color: "#fff", zIndex: 10 }}
				open={isOpen}
				onClick={handleClose}
			>
				<div style={{ width: "60%", border: 10 }}>
					<ProgressiveImage src={activeAlert.image} alt="" />
				</div>
			</Backdrop>
		);
	}
};

export default HistoryPageTriggerImageOverlay;

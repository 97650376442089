import { useSelector } from "react-redux";

import CamerasListDetailed from "./CamerasListDetailed";
import { selectActiveCameraView } from "../../../../../store/slices/routingSlice";
import CameraPreview from "../../../../../Components/CameraPreview/CameraPreview";
import { CAMERA_LIST_VIEWS } from "../../../MainPageSideMenu/Components/CamerasListSwitch";

import "./styles.css";

const CamerasList = ({ activeCompanyCameras }) => {
	const activeCameraView = useSelector(selectActiveCameraView);

	if (!activeCompanyCameras || activeCompanyCameras.length === 0)
		return <p className="camera-card-txt">No cameras for this company</p>;

	if (activeCameraView === CAMERA_LIST_VIEWS.LIST) {
		return (
			<div className="cameras-list-body">
				{activeCompanyCameras.map((camera) => {
					return <CameraPreview camera={camera} key={camera.id} />;
				})}
			</div>
		);
	}

	if (activeCameraView === CAMERA_LIST_VIEWS.DETAILED) {
		return <CamerasListDetailed activeCompanyCameras={activeCompanyCameras} />;
	}
};

export default CamerasList;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	activeModal: null,
	activeModalPayload: null,
	modalProps: null,
};

export const modalSlice = createSlice({
	name: "activeModal",
	initialState,
	reducers: {
		setActiveModal: (state, action) => {
			state.activeModal = action.payload;
		},
		deactivateModal: (state) => {
			state.activeModal = null;
		},
		setActiveModalPayload: (state, action) => {
			state.activeModalPayload = action.payload;
		},
		setActiveModalWithProps: (state, action) => {
			const { activeModal, props } = action.payload;
			state.activeModal = activeModal;
			state.modalProps = props;
		},
	},
});

export const {
	setActiveModal,
	deactivateModal,
	setActiveModalPayload,
	setActiveModalWithProps,
} = modalSlice.actions;

export const selectActiveModal = (state) => state.activeModal.activeModal;
export const selectActiveModalPayload = (state) =>
	state.activeModal.activeModalPayload;
export const selectActiveModalProps = (state) => state.activeModal.modalProps;

export default modalSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { CAMERA_LIST_VIEWS } from "../../Pages/MainPage/MainPageSideMenu/Components/CamerasListSwitch";
import { HISTORY_PAGE_COMPONENT } from "../../Pages/MainPage/MainPageSideMenu/Components/HistoryPageGraphOption";

const initialState = {
	activeRoute: null,
	activeCameraView: CAMERA_LIST_VIEWS.LIST,
	activeCameraForDetailedList: null,
	activeHistoryPageComponent: HISTORY_PAGE_COMPONENT.TABLE,
};

export const routingSlice = createSlice({
	name: "routing",
	initialState,
	reducers: {
		setActiveRoute: (state, action) => {
			state.activeRoute = action.payload;
		},
		setActiveCameraView: (state, action) => {
			state.activeCameraView = action.payload;
		},
		setActiveCameraForDetailedList: (state, action) => {
			state.activeCameraForDetailedList = action.payload;
		},
		setActiveHistoryPageComponent: (state, action) => {
			state.activeHistoryPageComponent = action.payload;
		},
	},
});

export const {
	setActiveRoute,
	setActiveCameraView,
	setActiveHistoryPageComponent,
	setActiveCameraForDetailedList,
} = routingSlice.actions;

export const selectActiveRoute = (state) => state.activeRoute.activeRoute;
export const selectActiveCameraView = (state) =>
	state.activeRoute.activeCameraView;
export const selectActiveCameraForDetailedList = (state) =>
	state.activeRoute.activeCameraForDetailedList;
export const selectActiveHistoryPageComponent = (state) =>
	state.activeRoute.activeHistoryPageComponent;

export default routingSlice.reducer;

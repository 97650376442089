import { Button } from "@mui/material";
import { useDispatch } from "react-redux";

import { formatDate } from "../../../../../../../utils/helpers";
import { ACTIVE_MODAL } from "../../../../../../../constants/app.constants";
import { setActiveModalWithProps } from "../../../../../../../store/slices/modalSlice";
import HasPermissions from "../../../../../../../Components/PermissionsFilters/HasPermissions";

import "./styles.css";

const buttonSx = {
	width: "45px",
	height: "45px",
	position: "absolute",
	right: "5px",
	top: "5px",
	backgroundColor: "#213a44",
};

const CameraHistory = ({ camera }) => {
	const dispatch = useDispatch();

	return (
		<div className="camera-history-body">
			<HasPermissions>
				<Button
					variant="contained"
					sx={buttonSx}
					onClick={() => {
						const payload = {
							activeModal: ACTIVE_MODAL.EDIT_CAMERA,
							props: {
								camera,
							},
						};
						dispatch(setActiveModalWithProps(payload));
					}}
				>
					<p className="history-page-filters-button-txt">Edit</p>
				</Button>
			</HasPermissions>
			<p className="camera-history-text-title">{camera.name}</p>
			<p className="camera-history-text-normal">
				Last modified: {formatDate(camera.updated_at)}
			</p>
			<HasPermissions>
				<p className="camera-history-text-normal">
					IP Address: {camera.ip_address}
				</p>
			</HasPermissions>
			<p className="camera-history-text-normal">
				Description: {camera.description}
			</p>
		</div>
	);
};

export default CameraHistory;

import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const CustomLink = ({ children, to, ...props }) => {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });

	return (
		<Link style={{ textDecoration: "none" }} to={to} {...props}>
			{React.cloneElement(children, { activelink: match })}
		</Link>
	);
};

export default CustomLink;

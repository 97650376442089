import { Tooltip } from "@mui/material";

import "./weatherIcon.styles.css";

const WeatherIcon = ({ image, title, active = false }) => {
	return (
		<Tooltip title={title} placement="bottom">
			<div className="weather-modal-icon">
				{!active ? (
					<div className="weather-modal-inactive-icon-overlay"></div>
				) : null}
				<img
					src={image}
					alt="thunderstorm"
					className="weather-modal-icon-img"
				/>
			</div>
		</Tooltip>
	);
};

export default WeatherIcon;

import { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
	selectActiveCompany,
	fetchCompanyAlertPhones,
} from "../../../../store/slices/userSlice";

import {
	setDataState,
	displayNotification,
} from "../../../../store/slices/appStateSlice";

import { deactivateModal } from "../../../../store/slices/modalSlice";
import { DATA_STATE, MODAL_TYPE } from "../../../../constants/app.constants";
import { createCompanyAlertPhone } from "../../../../Components/AppBase/appBase.services";
import ModalContainer from "../../../../Components/UiComponents/ModalContainer/ModalContainer";

import "./styles.newCompanyModal.css";

const buttonSx = {
	backgroundColor: "#213a44",
	width: "50%",
	alignSelf: "center",
	marginBottom: "20px",
	marginTop: "20px",
};

const AddAlertPhoneModal = () => {
	const dispatch = useDispatch();

	const activeCompany = useSelector(selectActiveCompany);

	const [phoneNumber, setPhoneNumber] = useState(null);
	const [name, setName] = useState(null);

	return (
		<ModalContainer title="Add new alert phone" width="30%">
			<div className="new-company-modal-body">
				<p className="new-company-modal-normal-txt">Enter your phone here:</p>
				<TextField
					placeholder="Phone"
					onChange={(e) => setPhoneNumber(e.target.value)}
				/>
				<br />
				<p className="new-company-modal-normal-txt">Enter your name here:</p>
				<TextField
					placeholder="Name"
					onChange={(e) => setName(e.target.value)}
				/>
				<Button
					variant="contained"
					disabled={!phoneNumber || !name}
					sx={buttonSx}
					onClick={async () => {
						dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
						let dataToSend = {
							company: activeCompany.id,
							phone_number: phoneNumber,
							contact_name: name,
						};
						let createNewPhone = await createCompanyAlertPhone(dataToSend);
						if (createNewPhone.data) {
							dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
							const payload = {
								text: "New phone added!",
								type: MODAL_TYPE.SUCCESS,
							};
							dispatch(displayNotification(payload));
							dispatch(fetchCompanyAlertPhones(activeCompany.id));
							dispatch(deactivateModal());
						} else if (createNewPhone.error) {
							dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
							const payload = {
								text: "Error adding phone",
								type: MODAL_TYPE.FAILURE,
							};
							dispatch(displayNotification(payload));
							dispatch(deactivateModal());
						}
					}}
				>
					<p
						style={{
							fontSize: "x-large",
							color: "white",
							fontFamily: "MollenBold",
						}}
					>
						Save
					</p>
				</Button>
			</div>
		</ModalContainer>
	);
};

export default AddAlertPhoneModal;

import { useEffect, useState } from "react";
import { Grow, Tooltip } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import ToggleButton from "@mui/material/ToggleButton";
import { useDispatch, useSelector } from "react-redux";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";

import {
	setActiveCameraView,
	selectActiveCameraView,
} from "../../../../store/slices/routingSlice";

import "./styles.css";

export const CAMERA_LIST_VIEWS = {
	LIST: "LIST",
	DETAILED: "DETAILED",
};

const CamerasListSwitch = () => {
	const [animate, setAnimate] = useState(true);
	const dispatch = useDispatch();
	const activeCameraView = useSelector(selectActiveCameraView);

	const handleViewSelection = (event, newSelection) => {
		if (newSelection) dispatch(setActiveCameraView(newSelection));
	};

	useEffect(() => {
		return () => {
			setAnimate(false);
		};
	}, []);

	return (
		<Grow in={animate}>
			<div className="suboption-container">
				<p
					style={{
						color: "white",
						fontSize: "large",
						fontFamily: '"Nunito", sans-serif',
						margin: 0,
					}}
				>
					View:
				</p>
				<ToggleButtonGroup
					value={activeCameraView}
					exclusive
					onChange={handleViewSelection}
					aria-label="camera list type"
				>
					<Tooltip title="List view">
						<ToggleButton value={CAMERA_LIST_VIEWS.LIST} aria-label="list">
							<FeaturedPlayListIcon
								color={
									activeCameraView === CAMERA_LIST_VIEWS.LIST
										? "mainGreen"
										: "colorWhite"
								}
								fontSize="large"
							/>
						</ToggleButton>
					</Tooltip>
					<Tooltip title="Alert view">
						<ToggleButton
							value={CAMERA_LIST_VIEWS.DETAILED}
							aria-label="detailed"
						>
							<ListIcon
								color={
									activeCameraView === CAMERA_LIST_VIEWS.DETAILED
										? "mainGreen"
										: "colorWhite"
								}
								fontSize="large"
							/>
						</ToggleButton>
					</Tooltip>
				</ToggleButtonGroup>
			</div>
		</Grow>
	);
};

export default CamerasListSwitch;

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { Badge, CardActionArea, CircularProgress } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { CAMERA_SNAPSHOT } from "../../../../../constants/url";
import { LOCAL_GREEN, LOCAL_ORANGE } from "../../../../../constants/colors";

import "./cameraCard.styles.css";
import HasPermissions from "../../../../../Components/PermissionsFilters/HasPermissions";

const cardSx = {
	minHeight: "32%",
	border: "3px solid",
	borderColor: "white",
	transition: "0.4s",
	"&:hover": {
		borderColor: LOCAL_GREEN,
	},
};

const activeCardSx = {
	minHeight: "32%",
	border: "3px solid",
	borderColor: LOCAL_ORANGE,
};

const CameraCard = ({
	camera,
	dataState,
	dataStateWatcher,
	showInfo = false,
}) => {
	return (
		<Card sx={showInfo ? activeCardSx : cardSx}>
			<CardActionArea>
				<CardContent>
					<div className="camera-card-content-container">
						<p className="camera-card-txt">{camera.name}</p>
						<HasPermissions>
							{dataState === dataStateWatcher ? (
								<CircularProgress size="small" />
							) : (
								<Badge badgeContent={camera.pending_alerts} color="cameraAlert">
									<NotificationsIcon size={25} />
								</Badge>
							)}
						</HasPermissions>
					</div>
				</CardContent>
				<CardMedia
					component="img"
					image={CAMERA_SNAPSHOT(camera.last_snapshot)}
					alt="green iguana"
				/>
			</CardActionArea>
		</Card>
	);
};

export default CameraCard;

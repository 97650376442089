import Badge from "@mui/material/Badge";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { TiWeatherCloudy } from "react-icons/ti";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { CAMERA_SNAPSHOT } from "../../constants/url";
import { ACTIVE_MODAL } from "../../constants/app.constants";

import {
	setActiveModal,
	setActiveModalWithProps,
} from "../../store/slices/modalSlice";

import { setActiveCamera } from "../../store/slices/userSlice";
import ProgressiveImageList from "../UiComponents/ImageLoaderAndWrapper/ProgressiveImageList";

import "./styles.cameraPreview.css";
import { setActiveCameraForDetailedList } from "../../store/slices/routingSlice";
import { setActiveCameraView } from "../../store/slices/routingSlice";
import { CAMERA_LIST_VIEWS } from "../../Pages/MainPage/MainPageSideMenu/Components/CamerasListSwitch";
import HasPermissions from "../PermissionsFilters/HasPermissions";

const CameraPreview = ({ camera, amountOfCameras = 3 }) => {
	const dispatch = useDispatch();

	const getCameraBodyStyle = (amountOfCameras) => {
		if (amountOfCameras === 1) {
			return "camera-preview-body-1-camera";
		}
		if (amountOfCameras < 3) {
			return "camera-preview-body-2-cameras";
		} else {
			return "camera-preview-body";
		}
	};

	return (
		<div
			className={getCameraBodyStyle(amountOfCameras)}
			onClick={() => {
				dispatch(setActiveCamera(camera));
				if (camera.id === 283) {
					dispatch(setActiveModal(ACTIVE_MODAL.DEMO_CAMERA_STREAM));
				} else {
					// dispatch(setActiveModal(ACTIVE_MODAL.CAMERA_STREAM));
				}
			}}
		>
			<div className="camera-preview-title-container">
				<p className="camera-preview-title-txt">{camera.name}</p>
				<div className="camera-preview-actions-container">
					<HasPermissions>
						<Tooltip title="Pending alerts">
							<div
								className="camera-preview-weather-icon"
								onClick={(e) => {
									e.stopPropagation();
									dispatch(setActiveCameraForDetailedList(camera.id));
									dispatch(setActiveCameraView(CAMERA_LIST_VIEWS.DETAILED));
								}}
							>
								<Badge badgeContent={camera.pending_alerts} color="cameraAlert">
									<NotificationsIcon size={25} />
								</Badge>
							</div>
						</Tooltip>
					</HasPermissions>
					{camera.lat && camera.lon ? (
						<Tooltip title="Weather forecast">
							<div
								className="camera-preview-weather-icon"
								onClick={(e) => {
									e.stopPropagation();
									const payload = {
										activeModal: ACTIVE_MODAL.WEATHER_MODAL,
										props: {
											lat: camera.lat,
											long: camera.lon,
										},
									};
									dispatch(setActiveModalWithProps(payload));
								}}
							>
								<TiWeatherCloudy size={25} />
							</div>
						</Tooltip>
					) : null}
				</div>
			</div>
			<Tooltip title="Watch livestream">
				<div className="camera-preview-image-box">
					<ProgressiveImageList
						src={CAMERA_SNAPSHOT(camera.last_snapshot)}
						alt=""
					/>
				</div>
			</Tooltip>
		</div>
	);
};

export default CameraPreview;

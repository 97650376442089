import { connect, useDispatch } from "react-redux";
import { deactivateModal } from "../../../../../store/slices/modalSlice";

import "./cameraStreamModal.styles.css";

// const socket = io.connect('167.235.241.215:8000');

const DemoCameraStreamModal = ({ camera }) => {
	const dispatch = useDispatch();

	if (!camera) return null;

	return (
		<div className="camera-stream-modal-wrapper">
			<div className="camera-stream-modal-form-wrapper">
				<div className="camera-stream-modal-header">
					<p className="camera-stream-modal-title-txt">Camera livestream</p>
					<p
						className="camera-stream-modal-exit-txt"
						onClick={() => {
							dispatch(deactivateModal());
						}}
					>
						X
					</p>
				</div>
				<div className="camera-stream-modal-body">
					<div className="camera-stream-frame">
						<video width="100%" height="100%" controls>
							<source
								src="https://api.spectro-solutions.com/media/demo_videos/video_1.mp4"
								type="video/mp4"
							/>
						</video>
						{/* <ReactPlayer url='http://167.235.241.215/media/snapshots/fire2.mp4' /> */}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	camera: state.user.activeCamera,
});

export default connect(mapStateToProps)(DemoCameraStreamModal);

import { useState } from "react";
import Menu from "@mui/material/Menu";
import Select from "@mui/material/Select";
import { FaRegUser } from "react-icons/fa";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import { connect, useDispatch, useSelector } from "react-redux";

import {
	fetchUser,
	selectActiveCompany,
} from "../../../store/slices/userSlice";

import HasPermissions from "../../../Components/PermissionsFilters/HasPermissions";

import "./styles.css";

const MainPageHeader = ({ user }) => {
	const dispatch = useDispatch();
	const activeCompany = useSelector(selectActiveCompany);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = () => {
		setAnchorEl(null);
		localStorage.clear();
		window.location.reload();
	};

	const handleCompanyChange = (event) => {
		dispatch(fetchUser(event.target.value));
	};

	if (!user || !activeCompany) return null;
	return (
		<div className="header-component-body">
			<div className="logo-box">
				<p className="logo-txt">Dashboard</p>
			</div>
			<div className="header-body-box">
				<div className="user-company-selectors-box">
					<HasPermissions>
						<div className="company-selector-box">
							{user && activeCompany ? (
								<FormControl fullWidth>
									<Select
										id="company-select"
										value={activeCompany.id}
										onChange={handleCompanyChange}
										className="selector"
									>
										{user.companies.map((company) => {
											return (
												<MenuItem value={company.id} key={company.id}>
													{company.name}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							) : (
								<CircularProgress />
							)}
						</div>
					</HasPermissions>

					{user ? (
						<div className="username-box">
							<p className="username-txt">{user.first_name}</p>
							<div
								className="user-icon-box"
								onClick={(event) => handleClick(event)}
							>
								<FaRegUser size={25} />
							</div>
						</div>
					) : (
						<CircularProgress />
					)}
				</div>
			</div>
			<Menu
				id="user-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={handleLogOut}>Logout</MenuItem>
			</Menu>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
});

export default connect(mapStateToProps)(MainPageHeader);

import { random } from "../../utils/helpers";

const monthName = (mon) => {
	return [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	][mon - 1];
};

export const addConfidenceToAlerts = (alerts) => {
	return alerts.map((alert) => {
		return { ...alert, confidence: random(85, 95) };
	});
};

export const filterAlertsByPermission = (alerts, isAdmin) => {
	if (!isAdmin) {
		const filteredAlerts = alerts.filter((alert) => {
			if (alert.status === "PENDING") {
				return alert;
			}
			return null;
		});
		return addConfidenceToAlerts(filteredAlerts).reverse();
	} else {
		return addConfidenceToAlerts(alerts).reverse();
	}
};

export const getAlertCameraNamesArray = (alerts) => {
	const allNames = alerts.map((alert) => {
		return alert.camera.name;
	});

	return [...new Set(allNames)];
};

export const createChartDataPoints = (alerts) => {
	const allMonths = alerts.map((alert) => {
		return new Date(alert.timestamp).getMonth();
	});
	const months = [...new Set(allMonths)];

	const allPerMonth = months.map((month) => {
		const count = alerts.filter((alert) => {
			return new Date(alert.timestamp).getMonth() === month;
		}).length;
		return { x: month, text: monthName(month), y: count };
	});

	const pendingPerMonth = months.map((month) => {
		const count = alerts.filter((alert) => {
			return (
				new Date(alert.timestamp).getMonth() === month &&
				alert.status === "PENDING"
			);
		}).length;
		return { x: month, text: monthName(month), y: count };
	});

	const approvedPerMonth = months.map((month) => {
		const count = alerts.filter((alert) => {
			return (
				new Date(alert.timestamp).getMonth() === month &&
				alert.status === "APPROVED"
			);
		}).length;
		return { x: month, text: monthName(month), y: count };
	});

	const declinedPerMonth = months.map((month) => {
		const count = alerts.filter((alert) => {
			return (
				new Date(alert.timestamp).getMonth() === month &&
				alert.status === "DECLINED"
			);
		}).length;
		return { x: month, text: monthName(month), y: count };
	});

	return {
		all: allPerMonth,
		pending: pendingPerMonth,
		approved: approvedPerMonth,
		declined: declinedPerMonth,
	};
};

import { TextField } from "@mui/material";

const InputAreaFormik = ({ field, form }) => {
	const { value, name, onBlur, onChange } = field;
	const { getFieldMeta } = form;
	const { error, touched } = getFieldMeta(name);

	const handleErrorDetection = () => {
		if (error) {
			return error;
		} else {
			return " ";
		}
	};

	return (
		<TextField
			rows={5}
			multiline
			name={name}
			title={error}
			onBlur={onBlur}
			onChange={onChange}
			value={value ? value : ""}
			error={!!error && touched}
			helperText={touched ? handleErrorDetection() : " "}
		/>
	);
};

export default InputAreaFormik;

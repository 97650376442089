import React, { useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { deactivateModal } from "../../../../../store/slices/modalSlice";
import ProgressiveImage from "../../../../../Components/UiComponents/ImageLoaderAndWrapper/ProgressiveImage";

import "./cameraStreamModal.styles.css";

import io from "socket.io-client";
import { useEffect } from "react";

// const socket = io.connect('167.235.241.215:8000');

const CameraStreamModal = ({ camera }) => {
	const dispatch = useDispatch();
	const [imgData, setImgData] = useState();
	const [imgLoading, setImgLoading] = useState(true);
	const socket = useRef();

	useEffect(() => {
		socket.current = io("http://167.235.241.215:8000");

		socket.current.on("connect", () => {
			console.log("socket connected");
		});

		socket.current.emit("stream", `${camera.id}`);
		socket.current.on("stream", (data) => {
			setImgData(data);
			setImgLoading(false);
		});

		return () => {
			socket.current.disconnect();
		};
	}, [camera]);

	if (!camera) return null;

	return (
		<div className="camera-stream-modal-wrapper">
			<div className="aa"></div>
			<div className="camera-stream-modal-form-wrapper">
				<div className="camera-stream-modal-header">
					<p className="camera-stream-modal-title-txt">Camera livestream</p>
					<p
						className="camera-stream-modal-exit-txt"
						onClick={() => {
							dispatch(deactivateModal());
						}}
					>
						X
					</p>
				</div>
				<div className="camera-stream-modal-body">
					<div className="camera-stream-frame">
						{/* <img src={`data:image/jpg;base64,${imgData}`} alt="kurcina" /> */}
						<ProgressiveImage
							src={`data:image/jpg;base64,${imgData}`}
							alt="Stream"
							imgLoading={imgLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	camera: state.user.activeCamera,
});

export default connect(mapStateToProps)(CameraStreamModal);

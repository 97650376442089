import { useState } from "react";
import { useDispatch } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";

import {
	Table,
	Paper,
	styled,
	Tooltip,
	TableBody,
	TableCell,
	TableHead,
	TableContainer,
} from "@mui/material";

import { deleteAlert } from "../camerasList.actions";
import { formatDate } from "../../../../../../utils/helpers";
import HasPermissions from "../../../../../../Components/PermissionsFilters/HasPermissions";
import HistoryPageTriggerImageOverlay from "../../../../../HistoryPage/Components/HistoryPageTriggerImageOverlay";

const tableHeadSx = {
	backgroundColor: "#23c8aa",
	borderRadius: "0",
};

const tableBodySx = {
	backgroundColor: "white",
};

const tableSx = {
	height: "max-content",
};

const handleStatusStyle = (status) => {
	if (status === "PENDING") {
		return "history-page-table-row-txt-status-pending";
	} else if (status === "APPROVED") {
		return "history-page-table-row-txt-status-approved";
	} else {
		return "history-page-table-row-txt-status-declined";
	}
};

const CamerasListDetailedTableHistoryLogs = ({ alerts, company, cameraId }) => {
	const StyledTableRow = styled(TableRow)(() => ({
		[`&.${tableRowClasses.root}`]: {
			height: "75px",
		},
	}));

	const dispatch = useDispatch();

	const [activeAlert, setActiveAlert] = useState(null);
	const [imageOverlayOpen, setImageOverlayOpen] = useState(false);

	const handleImageOverlayClose = (e) => {
		e.stopPropagation();
		setImageOverlayOpen(false);
	};

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="Alerts" sx={tableSx}>
					<TableHead sx={tableHeadSx}>
						<TableRow>
							<TableCell>
								<p className="history-page-table-header-txt">Alert</p>
							</TableCell>
							<TableCell>
								<div className="centered-container">
									<p className="history-page-table-header-txt">Time</p>
								</div>
							</TableCell>
							<TableCell>
								<div className="centered-container">
									<p className="history-page-table-header-txt">Trigger image</p>
								</div>
							</TableCell>
							<TableCell>
								<p className="history-page-table-header-txt">Status</p>
							</TableCell>
							<HasPermissions>
								<TableCell>
									<p className="history-page-table-header-txt">Action</p>
								</TableCell>
							</HasPermissions>
							{/* <TableCell /> */}
						</TableRow>
					</TableHead>
					<TableBody sx={tableBodySx}>
						{alerts?.map((alert) => {
							return (
								<StyledTableRow key={alert.id}>
									<TableCell>
										<p className="history-page-table-row-txt">Smoke</p>
									</TableCell>
									<TableCell>
										<div className="centered-container">
											<p className="history-page-table-row-txt">
												{formatDate(alert.timestamp)}
											</p>
										</div>
									</TableCell>
									<TableCell>
										<div
											className="trigger-image-container"
											onClick={() => {
												setActiveAlert(alert);
												setImageOverlayOpen(true);
											}}
										>
											<img
												className="trigger-image"
												src={alert.image}
												alt="trigger"
											/>
										</div>
									</TableCell>
									<TableCell>
										<p className={handleStatusStyle(alert.status)}>
											{alert.status}
										</p>
									</TableCell>
									<HasPermissions>
										<TableCell>
											<div className="action-icons-container">
												<Tooltip title="Delete">
													<div
														className="action-icon"
														onClick={(e) => {
															e.stopPropagation();
															dispatch(
																deleteAlert(alert.id, cameraId, company.id),
															);
														}}
													>
														<MdDeleteForever size={25} color="red" />
													</div>
												</Tooltip>
											</div>
										</TableCell>
									</HasPermissions>
								</StyledTableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<HistoryPageTriggerImageOverlay
				activeAlert={activeAlert}
				isOpen={imageOverlayOpen}
				handleClose={handleImageOverlayClose}
			/>
		</>
	);
};

export default CamerasListDetailedTableHistoryLogs;

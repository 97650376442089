import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import {
	setDataState,
	displayNotification,
} from "../../store/slices/appStateSlice";

import {
	dispatchFetchUser,
	dispatchFetchCompanyCameras,
	dispatchFetchCompanyAlertPhones,
} from "./loginPage.actions";

import logo from "../../assets/logo.png";
import { sleep } from "../../utils/helpers";
import { setToken } from "../../store/slices/userSlice";
import { DATA_STATE } from "../../constants/app.constants";
import { loginUser } from "../LoginPage/loginPage.actions";
import { MODAL_TYPE } from "../../constants/app.constants";

import "./styles.css";

const LoginPage_a = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [remmemberMe, setRemmemberMe] = useState(false);

	const handleRemmemberMe = (event) => {
		setRemmemberMe(event.target.checked);
	};

	const login = useCallback(async () => {
		const user = {
			email,
			password,
		};
		if (email === null || password === null) {
			const payload = {
				type: MODAL_TYPE.FAILURE,
				text: "Please enter your username and password",
			};
			dispatch(displayNotification(payload));
		}
		if (email === "" || password === "") {
			const payload = {
				type: MODAL_TYPE.FAILURE,
				text: "Please enter your username and password",
			};
			dispatch(displayNotification(payload));
			return;
		}
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		let loginData = await loginUser(user);
		if (loginData.data) {
			const tokens = {
				accessToken: loginData.data.access,
				refreshToken: remmemberMe ? loginData.data.refresh : null,
			};
			localStorage.setItem("accessToken", tokens.accessToken);
			if (tokens.refreshToken) {
				localStorage.setItem("refreshToken", tokens.refreshToken);
			}
			await sleep(1000);
			dispatch(setToken(tokens));
			dispatch(setDataState(DATA_STATE.DATA_STATE_OK));

			const companyId = await dispatch(dispatchFetchUser());
			await dispatch(dispatchFetchCompanyCameras(companyId));
			await dispatch(dispatchFetchCompanyAlertPhones(companyId)).then(() => {
				navigate("/cameras");
				window.location.reload();
			});
		} else if (loginData.error) {
			const notificationPayload = {
				type: MODAL_TYPE.FAILURE,
				text: loginData.error,
			};
			dispatch(displayNotification(notificationPayload));
		}
		dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
	}, [dispatch, email, navigate, password, remmemberMe]);

	useEffect(() => {
		const keyEnter = (event) => {
			if (event.key === "Enter") {
				login();
				event.preventDefault();
			}
		};

		document.addEventListener("keydown", keyEnter);

		return () => {
			document.removeEventListener("keydown", keyEnter);
		};
	}, [login]);

	return (
		<div className="login-page-container">
			<div className="login-form-container">
				<div className="login-form-logo-container">
					<img src={logo} alt="" className="login-form-logo" />
				</div>
				<div className="login-form-welcome-container">
					<p className="login-form-welcome-title-txt">Hello,</p>
					<p className="login-form-welcome-subtitle-txt">welcome!</p>
				</div>
				<div className="login-form-input-container">
					<input
						type="text"
						className="login-form-input"
						placeholder="Email address"
						onChange={(event) => setEmail(event.target.value)}
					/>
					<input
						type="password"
						className="login-form-input"
						placeholder="Password"
						onChange={(event) => setPassword(event.target.value)}
					/>
					<div className="login-form-input-buttons-container">
						<div className="login-form-input-buttons-remmember-container">
							<div className="login-form-input-buttons-remmember-checkbox-container">
								<Checkbox
									checked={remmemberMe}
									onChange={handleRemmemberMe}
									id="checkbox"
								/>
							</div>
							<label htmlFor="checkbox">
								<p className="login-form-input-buttons-txt">Remember me</p>
							</label>
						</div>
						{/* <p className="login-form-input-buttons-txt">Forget password?</p> */}
					</div>
					<div className="login-form-input-login-button-container">
						<button className="login-form-input-login-button" onClick={login}>
							Log in
						</button>
					</div>
				</div>
				{/* <div className="login-form-options-container">
          <p className="login-form-options-txt">
            Don't have an account? Sign up now!
          </p>
        </div> */}
			</div>
			<div className="login-quotes-container"></div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps)(LoginPage_a);

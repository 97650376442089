import axios from "axios"
import { LOGIN_USER } from "../../constants/url";

export const loginUser = async (user) => {
    let data;
    let error;

    await axios
        .post(LOGIN_USER, user)
        .then(response => {
            data = response.data
        })
        .catch(err => {
            error = err.response.data.detail
        })

    return { data, error }
}
import React, { useState } from "react";
import { CircularProgress } from "@mui/material";

import "./progressiveImage.styles.css";
import { useEffect } from "react";

const ProgressiveImageList = ({ src, stopStream, imgLoading, ...props }) => {
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);
	const [imgSrc, setImgSrc] = useState(null);

	useEffect(() => {
		const img = new Image();
		img.src = src;
		img.onload = () => setImgSrc(src);
		setLoading(false);
		img.onerror = () => {
			setError(true);
		};
	}, [src]);

	if (loading)
		return (
			<div className="loading-wrapper">
				<CircularProgress />
			</div>
		);

	if (error)
		return (
			<div className="loading-wrapper">
				<p className="normal-txt-prog-img">No preview available</p>
			</div>
		);

	return (
		<img
			{...{ src: imgSrc, ...props }}
			alt={props.alt || ""}
			className="camera-preview-image"
		/>
	);
};

export default ProgressiveImageList;

import axiosInstance from "../../../../utils/axios";
import { TEST_ALERT_PHONE } from "../../../../constants/url";

export const testAlertPhone = async (phone_number) => {
    let data;
    let error;

    let dataToSend = {
        phone_number: phone_number
    }

    await axiosInstance.post(TEST_ALERT_PHONE, dataToSend)
        .then((response) => {
            data = response.data;
        })
        .catch((err) => {
            error = err.message;
        });

    return { data, error };
}
import { useDispatch, useSelector } from "react-redux";

import {
	Select,
	Button,
	MenuItem,
	InputLabel,
	FormControl,
	CircularProgress,
} from "@mui/material";

import {
	selectUser,
	resetFilter,
	selectFilterStatus,
} from "../../../store/slices/userSlice";

import { filterAlertsByStatus } from "../historyPage.actions";

import { ALERT_STATUS } from "../../../constants/app.constants";

import "./historyPageComponents.styles.css";

const buttonSx = {
	backgroundColor: "#213a44",
};

const HistoryPageTableFilters = ({ allAlerts }) => {
	const dispatch = useDispatch();

	const user = useSelector(selectUser);
	const filterStatus = useSelector(selectFilterStatus);

	const handleStatusChange = (event) => {
		dispatch(filterAlertsByStatus(event.target.value, allAlerts));
	};

	const handleFilterReset = () => {
		dispatch(resetFilter());
	};

	if (!user) return <CircularProgress />;

	return (
		<div className="history-page-filters-container">
			<div className="history-page-filters-select-container">
				<FormControl fullWidth>
					<InputLabel id="statusFilterLabel">Status</InputLabel>
					<Select
						labelId="statusFilterLabel"
						id="statusFilter"
						label="status"
						value={filterStatus}
						onChange={handleStatusChange}
						disabled={allAlerts.length === 0}
					>
						{user.is_admin ? (
							<MenuItem value={ALERT_STATUS.PENDING}>PENDING</MenuItem>
						) : null}
						<MenuItem value={ALERT_STATUS.APPROVED}>APPROVED</MenuItem>
						<MenuItem value={ALERT_STATUS.DECLINED}>DECLINED</MenuItem>
					</Select>
				</FormControl>
			</div>
			<Button
				variant="contained"
				sx={buttonSx}
				onClick={() => handleFilterReset()}
				disabled={allAlerts.length === 0}
			>
				<p className="history-page-filters-button-txt">Reset filters</p>
			</Button>
		</div>
	);
};

export default HistoryPageTableFilters;
